<template>
  <div>
    <div class="banner">
      <div class="title-wrapper">
        <div class="title">Automation System</div>
      </div>
    </div>
    <div class="container-wrapper">
      <div class="container">
        <div class="section">
          <div class="sub-title">공급 시스템 유형<span>(System type)</span></div>
          <div class="content-wrapper">
            <div class="box-wrapper">
              <img src="@/assets/img/content/automation/ct_supply.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'main'
}
</script>

<style scoped>

.banner {
  width: 100%;
  min-width: 1192px;
  height: 145px;
  margin: 0 auto;
  background-image: url('~@/assets/img/banner/banner_company.png');
}

.title-wrapper {
  position: relative;
  width: 1192px;
  height: 100%;
  margin: 0 auto;
  font-family: NanumBarunGothic;
  font-size: 28px;
  font-weight: 700;
  color: #ffffff;
}

.title-wrapper .title {
  position: absolute;
  left: 0;
  bottom: 20px;
}

.container-wrapper {
  width: 100%;
}

.container {
  width: 1192px;
  margin: 0 auto;
}

.section {
  padding-top: 46px;
  margin-bottom: 114px;
}

.section .sub-title {
  font-family: NanumBarunGothic;
  font-size: 24px;
  font-weight: 700;
  color: #383838;
}

.section .sub-title span {
  font-weight: normal;
}

.content-wrapper {
  margin-top: 35px;
}

.subject {
  font-family: NanumBarunGothic;
  font-size: 20px;
  font-weight: 700;  
  color: #383838;
}

.subject span {
  font-weight: normal;
}

.content-wrapper .box-wrapper {
  width: 100%;
}

.content-wrapper .box-wrapper img {
  width: 100%;
  object-fit: cover;
}

</style>