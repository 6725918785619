<template>
  <div class="container-wrapper">
    <div class="title-wrapper">
      <div class="title"><span>A</span>utomation System</div>
      <div class="subtitle">혁신적인 애거슨 자동제어 시스템을 경험해 보십시오.</div>
    </div>
    <div class="box-container">
      <div class="content-wrapper">
        <div class="content">
          <img src="@/assets/img/content/home/ct_auto_1.png" />
          <div class="text-container">
            <div class="text">대한민국 최초의 글로벌 의약품 규정으로 충족하는 자동제어 시스템을 공급하는 전문 기업</div>
            <div class="text light">
              외산 제조사 또는 직수입만을 의지하던 제약/바이오산업에 국내 최초로 순수 국내 엔지니어만으로<br />
              자동제어 시스템을 공급 하였습니다.<br />
              다수의 국제 감사기관을 통과하였으며, 기술의 축적을 통하여 해외로 수출 가능한 시스템을 개발하였습니다.
            </div>
          </div>
        </div>
      </div>
      <div class="content-wrapper">
        <div class="content">
          <div class="text-container">
            <div class="text">다양한 산업군에서 최적의 자동제어 시스템 도입을 도와주는 파트너 기업</div>
            <div class="text light">
              복잡하고 신뢰도 높은 자동제어 시스템을 구축한 경험을 바탕으로 다양한 산업군의 자동제어 시스템을 공급 하겠습니다.<br />
              단순한 시스템 공급 외 시스템 도입의 필요성을 함께 고민하겠습니다.<br />
              최적의 투자 효과를 보일 수 있도록 시스템 유지관리를 도와 드리겠습니다.
            </div>
          </div>
          <img src="@/assets/img/content/home/ct_auto_2.png" />
        </div>
      </div>
      <div class="content-wrapper">
        <div class="content">
          <img src="@/assets/img/content/home/ct_auto_3.png" />
          <div class="text-container">
            <div class="text">고객의 소리에 귀를 기울이며, 지속 성장하는 기업</div>
            <div class="text light">
              고객 만족에 최선을 다하겠습니다.<br />
              고객의 불만에서 기회를 찾고 고객이 원하는 신뢰할 수 있는 제품을 만들겠습니다.<br />
              변화를 두려워 하지 않고 혁신을 주도하여 시장을 선도하는 제품을 만들겠습니다.
            </div>
          </div>
        </div>
      </div>
      <div class="btn-wrapper">
        <div class="more">
          <a href="/automation/field">Learn More ▶</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>

<style scoped>
  
.container-wrapper {
  width: 100%;
  height: 100%;
  background-image: url('~@/assets/img/bg/bg_main_dark.png');
  background-repeat : no-repeat;
  background-size : cover;
}

.container {
  height: 100%;
  margin: 0 auto;
}

.title-wrapper {
  width: 1192px;
  margin: 0 auto;
  padding-top: 96px;
}

.title {
  font-family: NanumBarunGothic;
  font-size: 39px;
  color: #ffffff;
}

.title span {
  color: #99a0f8;
}

.subtitle {
  margin-top: 6px;
  font-family: NanumBarunGothic;
  font-size: 18px;
  font-weight: 300;
  color: #ffffff;
}

.box-container {
  margin-top: 32px;
  width: 100%;
}

.content-wrapper {
  width: 100%;
  padding: 9px 0;
}

.content-wrapper:nth-child(n+1) {
  margin-top: 19px;
}

.content-wrapper:nth-child(2n-1) {
  background-color: rgba(0, 0, 0, 0.3);
}

.content-wrapper:nth-child(2n) {
  background: none;
}

.content {
  width: 1192px;
  margin: 0 auto;
  overflow: auto;
}

.content-wrapper:nth-child(2) .content {
  display: flex;
  justify-content: space-between;
}

.text-container {
  float: left;
}

.content-wrapper:nth-child(2n-1) .content .text-container {
  padding: 11px 0px 11px 24px;
}

.content-wrapper:nth-child(2n) .content .text-container {
  padding: 11px 0;
}

.content img {
  float: left;
}

.text {
  font-family: NanumBarunGothic;
  font-size: 20px;
  color: #ffffff;
  letter-spacing: -1px;
}

.text.light {
  margin-top: 12px;
  font-size: 16px;
  font-weight: 300;
}

.btn-wrapper {
  width: 1192px;
  margin: 0 auto;
  text-align: right;
}

.btn-wrapper .more {
  width: 100%;
  margin-top: 11px;  
  text-align: right;
}

.btn-wrapper .more a {
  font-family: NanumBarunGothic;
  font-size: 19px;
  font-weight: 700;
  color: #ffffff;
  text-decoration: none;
}

</style>