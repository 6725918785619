<template>
  <div class="container-wrapper">
    <div class="container">
      <div class="title-wrapper">
        <div class="title"><span>G</span>MP Consulting</div>
        <div class="more">
          <a href="/consulting/design">Learn More ▶</a>
        </div>
      </div>
      <div class="content">
        <div class="item-list">
          <div class="item-border">
            <div class="item">
              <div class="text">개념설계</div>
              <div class="text light">Concept Design</div>
            </div>
          </div>
          <div class="item-border">
            <div class="item">
              <div class="text">밸리데이션 컨설팅</div>
              <div class="text light">Validation Consulting</div>
            </div>
          </div>
          <div class="item-border">
            <div class="item">
              <div class="text">사전 실사</div>
              <div class="text light">Mock Inspection</div>
            </div>
          </div>
          <div class="item-border">
            <div class="item">
              <div class="text">PQS Consulting</div>
              <div class="text light">Pharmaceutical<br />Quality System</div>
            </div>
          </div>
          <div class="item-border">
            <div class="item">
              <div class="text">정기검토</div>
              <div class="text light">Periodic Review</div>
            </div>
          </div>
          <div class="item-border">
            <div class="item">
              <div class="text">정보화전략계획</div>
              <div class="text light">Information Strategy<br />Planning</div>
            </div>
          </div>
        </div>
        <div class="slide-wrapper">
          <vueper-slides
            arrows-outside
            :bullets="false"
            :autoplay="true"
            fixed-height="323px"
          >
            <vueper-slide
              v-for="(slide, i) in slides"
              :key="i"
              :image="slide.image"
            />
          </vueper-slides>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  data() {
    return {
      slides: [
        {
          title: 'Slide #1',
          image: require('@/assets/img/content/home/consulting/slide_1.jpg')
        },
        {
          title: 'Slide #2',
          image: require('@/assets/img/content/home/consulting/slide_2.jpg')
        },
        {
          title: 'Slide #3',
          image: require('@/assets/img/content/home/consulting/slide_3.jpg')
        },
        {
          title: 'Slide #4',
          image: require('@/assets/img/content/home/consulting/slide_4.jpg')
        },
        {
          title: 'Slide #5',
          image: require('@/assets/img/content/home/consulting/slide_5.jpg')
        }
      ]
    }
  },
  components: {
    VueperSlides,
    VueperSlide 
  }
}
</script>

<style scoped>
  
.container-wrapper {
  width: 100%;
  height: 100%;
  padding: 0 30px;
  background-image: url('~@/assets/img/bg/bg_main_dark.png');
  background-repeat : no-repeat;
  background-size : cover;
}

.container {
  width: 1192px;
  height: 100%;
  margin: 0 auto;
}

.title-wrapper {
  padding-top: 100px;
  padding-left: 9px;
}

.title {
  font-family: NanumBarunGothic;
  font-size: 39px;
  color: #ffffff;
}

.title span {
  color: #99a0f8;
}

.more {
  margin-top: 5px;  
}

.more a {
  font-family: NanumBarunGothic;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  text-decoration: none;
}

.content {
  margin-top: 42px;
}

.item-list {
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
}

.item-border {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 176px;
  height: 176px;
  border: solid 1px transparent;
  border-radius: 88px;
}

.item-border:nth-child(2n-1) {
  border-left: solid 1px rgba(255, 255, 255, 0.36);
  border-bottom: solid 1px rgba(255, 255, 255, 0.36);
  transform: rotate(-45deg);
}

.item-border:nth-child(2n) {
  border-right: solid 1px rgba(255, 255, 255, 0.36);
  border-top: solid 1px rgba(255, 255, 255, 0.36);
  transform: rotate(-45deg);
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 150px;
  height: 150px;
  background-color: rgba(0, 0, 0, 0.46);
  border: 1px solid #ffffff;
  border-radius: 75px;
  transform: rotate(45deg);
}

.item-border.light .item {
  background-color: rgba(255, 255, 255, 0.23);
}

.text {
  font-family: NanumBarunGothic;
  font-size: 15px;
  font-weight: 700;
  color: #ffffff;
}

.text.light {
  margin-top: 4px;
  font-family: NanumBarunGothic;
  font-size: 12px;
  color: #b1b1b1;
  text-align: center;
}

.slide-wrapper {
  width: 758px;
  margin: 0 auto;
  margin-top: 57px;
}

>>> .vueperslides__arrow {
  color: #fff;
}

>>> .vueperslides__arrow svg {
  width: 3em;
}

</style>