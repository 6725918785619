<template>
  <div>
    <div class="header-wrapper">
      <Header></Header>
    </div>
    <div class="main-container">
      <div class="content-wrapper">
        <full-page :options="options">
          <div class="section">
            <Main></Main>
          </div>
          <div class="section">
            <Company></Company>
          </div>
          <div class="section">
            <Consulting></Consulting>
          </div>        
          <div class="section">
            <Validation></Validation>
          </div>
          <div class="section">
            <Automation></Automation>
          </div>
          <div class="section">
            <Contact></Contact>
          </div>
        </full-page>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/common/header'
import Main from '@/components/home/Main'
import Company from '@/components/home/Company'
import Consulting from '@/components/home/Consulting'
import Validation from '@/components/home/Validation'
import Automation from '@/components/home/Automation'
import Contact from '@/components/home/Contact'
export default {
  data() {
    return {
      options: {
        anchors: ['main', 'company', 'consulting', 'validation', 'automation', 'contact'],
        scrollOverflow: true,
        navigation: true,
        navigationTooltips: ['01.Main', '02.Company', '03.GMP Consulting', '04.Validation', '05.Automation system', '06.Contact'],
        showActiveTooltip: true,
        slidesNavigation: true
      }
    }
  },
  components: {
    Header,
    Main,
    Company,
    Consulting,
    Validation,
    Automation,
    Contact
  }
}
</script>

<style>

.header-wrapper {
  width: 100%;
  min-width: 1192px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #fff;
}

.main-container {
  min-width: 1192px;
  height: 100vh;
  margin-top: 114px;
}

.content-wrapper {
  width: 100%;
  height: 100%;
}

#fp-nav ul li a span {
  background: #fff !important;
}

</style>