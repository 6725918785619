<template>
  <div>
    <div class="banner">
      <div class="title-wrapper">
        <div class="title">Contact</div>
      </div>
    </div>
    <div class="container-wrapper">
      <div class="container">
        <div class="section">
          <div class="sub-title"><span>오시는 길</span></div>
          <div class="content-wrapper">
            <div class="content">
              <div class="info-container">
                <div class="info-wrapper">
                  <div class="info">
                    <div class="label">주소</div>
                    <div class="text"><span>경기도 화성시 동탄면 동탄산단 9길, 12 </span>(경기도 화성시 동탄면 방교리 826-10)</div>
                  </div>
                </div>
                <div class="info-wrapper">
                  <div class="info">
                    <div class="label">TEL</div>
                    <div class="text"><span>031 - 831 - 5778</span></div>
                  </div>
                  <div class="info">
                    <div class="label">FAX</div>
                    <div class="text"><span>031 - 831 - 5779</span></div>
                  </div>
                </div>
              </div>
              <img src="@/assets/img/logo/logo_white.png" />
            </div>
            <div id="map" ref="map"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    }
  },
  mounted() {
    const map = new naver.maps.Map(this.$refs.map, {
      center: new naver.maps.LatLng(37.174844, 127.088916),
      zoom: 15
    })

    new naver.maps.Marker({
      position: new naver.maps.LatLng(37.174844, 127.088916),
      map: map
    })
  }
}
</script>

<style scoped>

.banner {
  width: 100%;
  min-width: 1192px;
  height: 145px;
  margin: 0 auto;
  background-image: url('~@/assets/img/banner/banner_company.png');
}

.title-wrapper {
  position: relative;
  width: 1192px;
  height: 100%;
  margin: 0 auto;
  font-family: NanumBarunGothic;
  font-size: 28px;
  font-weight: 700;
  color: #ffffff;
}

.title-wrapper .title {
  position: absolute;
  left: 0;
  bottom: 20px;
}

.container-wrapper {
  width: 100%;
}

.container {
  width: 1192px;
  margin: 0 auto;
}

.section {
  padding-top: 46px;
  margin-bottom: 114px;
}

.section .sub-title {
  font-family: NanumBarunGothic;
  font-size: 24px;
  color: #383838;
}

.section .sub-title span {
  font-weight: 700;
}

.content-wrapper {
  margin-top: 40px;
}

.content-wrapper .content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.content-wrapper .content .info-container {
  
}

.content-wrapper .content .info-container .info-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 13px;
}

.content-wrapper .content .info-container .info-wrapper .info {
  display: flex;
}

.content-wrapper .content .info-container .info-wrapper .info .label {
  width: 106px;
  height: 31px;
  line-height: 31px;
  border-radius: 15px;
  background-color: rgba(49, 62, 119, 0.17);
  font-family: NanumBarunGothic;
  font-size: 18px;
  font-weight: 700; 
  text-align: center;
  color: #313e77;
}

.content-wrapper .content .info-container .info-wrapper .info .text {
  height: 31px;
  line-height: 31px;
  padding-left: 17px;
  font-family: NanumBarunGothic;
  font-size: 18px;
  font-weight: 300; 
  color: #313e77;
}

.content-wrapper .content .info-container .info-wrapper .info .text span {
  font-weight: 700;
}

#map {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 35%;
  margin: auto;
  margin-top: 20px;
}

</style>