<template>
  <div>
    <div class="banner">
      <div class="title-wrapper">
        <div class="title">GMP Consulting</div>
      </div>
    </div>
    <div class="container-wrapper">
      <div class="container">
        <div class="section">
          <div class="sub-title"><span>PQS </span>(Pharmaceutical Quality System) <span>Consulting</span></div>
          <div class="content-wrapper">
            <div class="card">
              <div class="card-title">PQS Consulting</div>
              <div class="card-text">
                <img src="@/assets/img/content/consulting/ct_pqs.png" />
                <div class="text-container">
                  <ul class="w-6">
                    <li>Quality System</li>
                    <li>Laboratory Control System </li>
                    <li>Packaging&amp;Labeling System</li>
                  </ul>
                  <ul class="w-6">
                    <li>Production System</li>
                    <li>Material System</li>
                    <li>Facilities&amp;Equipment System</li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-title dark">매뉴얼/기준서/SOPs Development</div>
              <div class="card-text">
                <img src="@/assets/img/content/consulting/ct_manual.png" />
                <div class="text-container">
                  <ul>
                    <li class="more">업종(의약품/의료기기/화장품/건강기능식품 등) 특성에 맞는 PQS 구축</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'main'
}
</script>

<style scoped>

.banner {
  width: 100%;
  min-width: 1192px;
  height: 145px;
  margin: 0 auto;
  background-image: url('~@/assets/img/banner/banner_company.png');
}

.title-wrapper {
  position: relative;
  width: 1192px;
  height: 100%;
  margin: 0 auto;
  font-family: NanumBarunGothic;
  font-size: 28px;
  font-weight: 700;
  color: #ffffff;
}

.title-wrapper .title {
  position: absolute;
  left: 0;
  bottom: 20px;
}

.container-wrapper {
  width: 100%;
}

.container {
  width: 1192px;
  margin: 0 auto;
}

.section {
  padding-top: 46px;
  margin-bottom: 114px;
}

.section .sub-title {
  font-family: NanumBarunGothic;
  font-size: 24px;
  color: #383838;
}

.section .sub-title span {
  font-weight: 700;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.content-wrapper .card .card-title {
  width: 582px;
  height: 50px;
  background-image: linear-gradient(to top, #414f92, #6072c8);
  font-family: NanumBarunGothic;
  font-size: 21px;
  font-weight: 700;  
  line-height: 50px;
  text-align: center;
  color: #ffffff;
}

.content-wrapper .card .card-title.dark {
  background-image: linear-gradient(to top, #313e77, #293258);
}


.content-wrapper .card .card-text {
  width: 582px;
  margin-top: 8px;
}

.content-wrapper .card .card-text .text-container {
  display: flex;
  width: 100%;
  margin-top: 10px;
}

.content-wrapper .card .card-text .text-container ul {
  list-style: none;
}

.content-wrapper .card .card-text .text-container ul.w-6 {
  width: 50%;
}

.content-wrapper .card .card-text .text-container ul li {
  height: 30px;
  font-family: NanumBarunGothic;
  font-size: 18px;
  line-height: 1.41;
  color: #363636;
}

.content-wrapper .card .card-text .text-container ul li.more {
  font-size: 17px;
}

.content-wrapper .card .card-text .text-container ul li::before {
  content: "\2022";
  color: #313e77;
  display: inline-block; 
  width: 0.8em;
  margin-left: -2em;
}

</style>