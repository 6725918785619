<template>
  <div>
    <div class="banner">
      <div class="title-wrapper">
        <div class="title">Automation System</div>
      </div>
    </div>
    <div class="container-wrapper">
      <div class="container">
        <div class="section">
          <div class="sub-title">산업군별 적용 사례<span>(Applications in each industry)</span></div>
          <div class="content-wrapper">
            <div class="content">
              <div class="subject">APPLICATION IN <span>BIO &amp; Pharmaceutical</span></div>
              <div class="paragraph">
                <div class="opinion">“ 바이오 및 의약품 산업에서는 국내/국제적 규정을 준수하는 자동 제어 시스템을 요구하고 있습니다.</div>
                <div class="explanation">(KFDA, PIC/s, US FDA 21 CFR Part 11, euGMP Annex 11)<br />또한 사용자는 국제/국내의 감시 기관에서 전자 기록의 강조로 데이터의 무결성을 증명해야 하는 어려운 현실을 맞이 하였습니다.</div>
              </div>
              <div class="paragraph">
                <div class="opinion">“ 애거슨은 지난 15년간 바이오/제약산업에서 다양한 자동화 시스템을 공급하였습니다.</div>
                <div class="explanation">모든  국내/국제 규제기관에서 요구되는 항목을 모두 충족시킨 최적의 자동화 시스템 구축하였으며, 다수의 실사 대응에 만족하는 시스템을 공급 하였습니다.  </div>
                <div class="image-box">
                  <img src="@/assets/img/logo/automation/logo_kfda.png" />
                  <img src="@/assets/img/logo/automation/logo_fda.png" />
                  <img src="@/assets/img/logo/automation/logo_emea.png" />
                  <img src="@/assets/img/logo/automation/logo_who.png" />
                  <img src="@/assets/img/logo/automation/logo_pic.png" />
                </div>
              </div>
              <div class="paragraph-container">
                <div class="paragraph ">
                  <div class="opinion">“ 애거슨은 바이오, 제약 산업에 필요한 다양한 제어 시스템을 공급 합니다.</div>
                  <div class="explanation">
                    <ul>
                      <li>PROCESS : BATCH CONTROL, BIO FERMENTOR, PURIFICATION, REACTOR,<br /><span>WEIGHING, FILTER DRY, VACUUM DRY, CIP/SIP, SCADA SYSTEM</span></li>
                      <li>UTILITY : BMS, EMS, PW, WFI, CS, WWT, BIOKILL</li>
                    </ul>
                  </div>
                </div>
                <img src="@/assets/img/content/automation/ct_laboratory.png" />
              </div>
              <div class="paragraph">
                <div class="opinion">
                  “ 애거슨의 자동화 시스템은 바이오 제약 산업 외 건강식품, 기능성 화장품, 의료진단키트, 의료 기기, 동물의약품,<br />
                  &nbsp;&nbsp;한방의약품 산업에서도 적용가능한 시스템을 공급 합니다.
                </div>
                <div class="explanation">아직 법률 또는 규정이 확립되지 않는 산업군을 대상으로 규제 대응을 대비한 최적의 시스템을 제안 합니다.</div>
                <div class="image-box">
                  <div class="img-item">
                    <div class="img-wrapper">
                      <img src="@/assets/img/content/automation/ct_food.png" />
                    </div>
                    <div class="label">건강식품</div>
                  </div>
                  <div class="img-item">
                    <div class="img-wrapper">
                      <img src="@/assets/img/content/automation/ct_cosmetics.png" />
                    </div>
                    <div class="label">기능성화장품</div>
                  </div>
                  <div class="img-item">
                    <div class="img-wrapper">
                      <img src="@/assets/img/content/automation/ct_injection.png" />
                    </div>
                    <div class="label">의료기기, 진단키드</div>
                  </div>
                  <div class="img-item">
                    <div class="img-wrapper">
                      <img src="@/assets/img/content/automation/ct_animal.png" />
                    </div>
                    <div class="label">동물의약품</div>
                  </div>
                  <div class="img-item">
                    <div class="img-wrapper">
                      <img src="@/assets/img/content/automation/ct_oriental.png" />
                    </div>
                    <div class="label">한방의약품</div>
                  </div>
                </div>
              </div>
              <div class="paragraph">
                <div class="opinion">“ 애거슨은 자동제어 시스템 공급외에 최고 수준의 컴퓨터 시스템 밸리데이션 서비스를 함께 공급합니다.</div>
                <div class="explanation">또한 밸리데이션 컨설팅 외 Good Engineering Practice (GEP) 엔지니어링 활동을 수행하며 활동 문서를 공급 합니다. </div>
                <div class="image-box labeless">
                  <img src="@/assets/img/content/automation/ct_cycle.png" />             
                  <img src="@/assets/img/content/automation/ct_step.png" />         
                  <img src="@/assets/img/content/automation/ct_gamp.png" />         
                </div>
              </div>
            </div>

            <div class="content">
              <div class="subject">APPLICATION IN <span>FINE CHEMICAL</span></div>
              <div class="paragraph">
                <div class="opinion">“ 정밀화학 분야의 자동제어 시스템은 중앙 집중화, 높은 안정성, 정밀제어, 무인화 등 고도의 자동화 기술을 요구 합니다.</div>
              </div>
              <div class="paragraph">
                <div class="opinion">“ 애거슨은 원료 합성 및 정밀화학 분야에서 다수의 플랜트 구축 경험을 갖고 있으며, 고객에게 최적화된 자동제어 시스템을 공급합니다.</div>
                <div class="explanation">
                  <ul>
                    <li>PROCESS : BATCH CONTROL, BIO FERMENTOR, PURIFICATION, REACTOR, WEIGHING, FILTER DRY,<br /><span>VACUUM DRY, CIP/SIP, SCADA SYSTEM</span></li>
                    <li>UTILITY : BMS, EMS, PW, WFI, CS, WWT, BIOKILL</li>
                  </ul>
                </div>
                <div class="image-container">
                  <img class="w-6" src="@/assets/img/content/automation/ct_factory.png" />
                  <img class="w-6" src="@/assets/img/content/automation/ct_office.png" />
                </div>
              </div>
            </div>

            <div class="content">
              <div class="subject">APPLICATION IN <span>Coal-Fired Power Plant</span></div>
              <div class="paragraph">
                <div class="opinion">“ 화력 발전소에 필요로 하는 자동제어 시스템을 공급 합니다. </div>
                <div class="explanation">
                  <ul>
                    <li>Coal &amp; Ash Handle Control System</li>
                    <li>Facility Management System</li>
                    <li>Energy Management System</li>
                    <li>Waste Water Treatment System</li>
                  </ul>
                </div>
                <div class="image-container">
                  <img class="w-12" src="@/assets/img/content/automation/ct_power.png" />
                </div>
              </div>
            </div>

            <div class="content">
              <div class="subject">APPLICATION IN <span>MILITARY</span></div>              
              <div class="paragraph">
                <div class="opinion">“ 군/경찰 개인 화력의 강화를 위하여 사용되는 사격 통제 시스템을 제공합니다.</div>
                <div class="explanation">다수의 공급 실적을 보유 하고 있습니다.</div>
                <div class="image-container">
                  <img class="w-6" src="@/assets/img/content/automation/ct_gun.png" />
                  <img class="w-6" src="@/assets/img/content/automation/ct_rifle.png" />
                </div>
              </div>
              <div class="btn-wrapper">
                <a class="btn" href="/files/자동제어(Automation_System)_주요수행실적.xlsx" download>
                  <img class="btn" src="@/assets/img/button/btn_download.png" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'main'
}
</script>

<style scoped>

.banner {
  width: 100%;
  min-width: 1192px;
  height: 145px;
  margin: 0 auto;
  background-image: url('~@/assets/img/banner/banner_company.png');
}

.title-wrapper {
  position: relative;
  width: 1192px;
  height: 100%;
  margin: 0 auto;
  font-family: NanumBarunGothic;
  font-size: 28px;
  font-weight: 700;
  color: #ffffff;
}

.title-wrapper .title {
  position: absolute;
  left: 0;
  bottom: 20px;
}

.container-wrapper {
  width: 100%;
}

.container {
  width: 1192px;
  margin: 0 auto;
}

.section {
  padding-top: 46px;
  margin-bottom: 114px;
}

.section .sub-title {
  font-family: NanumBarunGothic;
  font-size: 24px;
  font-weight: 700;
  color: #383838;
}

.section .sub-title span {
  font-weight: normal;
}

.section .btn-wrapper {
  width: 100%;
  margin-top: 25px;
  overflow: auto;
}

.section .btn-wrapper img.btn {
  float: right;
  cursor: pointer;
}

.content-wrapper {
  margin-top: 20px;
}

.content-wrapper .content {
  padding: 65px 0;
  border-top: 2px solid rgba(175, 175, 175, 0.1);
}

.content-wrapper .content:first-child {
  padding-top: 0;
  border: 0;
}

.content-wrapper .content:last-child {
  padding-bottom: 0;
}

.content-wrapper .content .subject {
  padding-bottom: 10px;
  font-family: NanumBarunGothic;
  font-size: 20px;
  color: #383838;
}

.content-wrapper .content .subject span {
  font-weight: 700;
}

.content-wrapper .content .paragraph-container {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
  margin-bottom: 125px;
}

.content-wrapper .content .paragraph-container .paragraph {
  margin-top: 0;
}

.content-wrapper .content .paragraph {
  margin-top: 35px;
}

.content-wrapper .content .paragraph .opinion {
  font-family: NanumBarunGothic;
  font-size: 21px;
  font-weight: 700;
  color: #313e77;
}

.content-wrapper .content .paragraph .explanation {
  margin-top: 8px;
  padding: 0 15px;
  font-family: NanumBarunGothic;
  font-size: 18px;
  line-height: 1.27;
  text-align: left;
  color: #383838;
}

.content-wrapper .content .paragraph .explanation ul {
  list-style: none;
}

.content-wrapper .content .paragraph .explanation ul li {
  line-height: 1.5;
}

.content-wrapper .content .paragraph .explanation ul li::before {
  content: "\2022";
  color: #313e77;
  display: inline-block; 
  width: 0.8em;
  margin-left: -2em;
}

.content-wrapper .content .paragraph .explanation ul li span {
  margin-left: -20px;
}

.content-wrapper .content .paragraph .image-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 1177px;
  margin: 15px 0;
  margin-left: 15px;
  padding: 15px 0;
  border: solid 1px #bcbcbc;
}

.content-wrapper .content .paragraph .image-box.labeless {
  height: 240px;
}

.content-wrapper .content .paragraph .image-box .img-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content-wrapper .content .paragraph .image-box .img-item .img-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 171px;
  height: 138px;
}

.content-wrapper .content .paragraph .image-box .img-item .label {
  font-family: NanumBarunGothic;
  font-size: 18px;
  text-align: center;
  color: #383838;
}

.content-wrapper .content .paragraph .image-container {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.content-wrapper .content .paragraph .image-container img.w-6 {
  width: 585px;
}

.content-wrapper .content .paragraph .image-container img.w-12 {
  width: 1192px;
}

</style>