<template>
  <div>
    <div class="header-wrapper">
      <Header></Header>
    </div>
    <div class="main">
      <router-view/>
    </div>
    <div class="footer-wrapper">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Header from '@/components/common/header'
import Footer from '@/components/common/footer'
export default {
  data() {
    return {
      
    }
  },
  components: {
    Header,
    Footer
  }
}
</script>

<style>

.header-wrapper {
  width: 100%;
  min-width: 1192px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #fff;
}

.main {
  min-width: 1192px;
  min-height: calc(100vh - 69px);
  margin-top: 114px;
  overflow: auto;
}

.footer-wrapper {
  width: 100%;
  min-width: 1192px;
}

</style>