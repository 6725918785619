<template>
  <div class="container-wrapper">
    <div class="container">
      <div class="title-wrapper">
        <div class="title"><span>C</span>ontact</div>
      </div>
      <div class="content">
        <div class="info-container">
          <div class="card">
            <div class="card-title">밸리데이션 사업부</div>
            <div class="card-text">
              <div class="table">
                <div class="row">
                  <div class="column">
                    <span class="label">담 당 자</span>
                    <span class="text">김성학 수석</span>
                  </div>
                  <div class="column">
                    <span class="label">T E L</span>
                    <span class="text">031-831-5778</span>
                  </div>
                </div>
                <div class="row">
                  <div class="column">
                    <span class="label">E-mail</span>
                    <span class="text">shkim@agathon.co.kr</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-title">시스템 사업부</div>
            <div class="card-text">
              <div class="table">
                <div class="row">
                  <div class="column">
                    <span class="label">담 당 자</span>
                    <span class="text">신형호 이사</span>
                  </div>
                  <div class="column">
                    <span class="label">T E L</span>
                    <span class="text">031-831-5778</span>
                  </div>
                </div>
                <div class="row">
                  <div class="column">
                    <span class="label">E-mail</span>
                    <span class="text">hhshin@agathon.co.kr</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-title">컴퓨터시스템 밸리데이션 사업부</div>
            <div class="card-text">
              <div class="table">
                <div class="row">
                  <div class="column">
                    <span class="label">담 당 자</span>
                    <span class="text">김준범 수석</span>
                  </div>
                  <div class="column">
                    <span class="label">T E L</span>
                    <span class="text">031-831-5778</span>
                  </div>
                </div>
                <div class="row">
                  <div class="column">
                    <span class="label">E-mail</span>
                    <span class="text">jbkim0245@agathon.co.kr</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="map" ref="map"></div>
      </div>
    </div>
    <div class="footer-wrapper">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Footer from '@/components/common/footer'

export default {
  components: {
    Footer
  },
  data() {
    return {

    }
  },
  mounted() {
    const map = new naver.maps.Map(this.$refs.map, {
      center: new naver.maps.LatLng(37.174844, 127.088916),
      zoom: 15
    })

    new naver.maps.Marker({
      position: new naver.maps.LatLng(37.174844, 127.088916),
      map: map
    })
  }
}
</script>

<style scoped>
  
.container-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 30px;
  background-image: url('~@/assets/img/bg/bg_main_dark.png');
  background-repeat : no-repeat;
  background-size : cover;
}

.container {
  width: 1192px;
  height: 100%;
  margin: 0 auto;
}

.title-wrapper {
  padding-top: 100px;
  padding-left: 9px;
}

.title {
  font-family: NanumBarunGothic;
  font-size: 39px;
  color: #ffffff;
}

.title span {
  color: #99a0f8;
}

.content {
  display: flex;
  width: 100%;
  margin-top: 25px;
}

.content .info-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  height: 424px;
}

.content .info-container .card {
  width: 100%;
}

.content .info-container .card .card-title {
  width: 100%;
  height: 44px;
  padding-left: 15px;
  line-height: 46px;
  background-color: rgba(0, 0, 0, 0.37);
  font-family: NanumBarunGothic;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  color: #ffffff;
}

.content .info-container .card .card-text {
  width: 100%;
  overflow: auto;
  padding-left: 15px;
}

.content .info-container .card .card-text .table {
  width: 100%;
}

.content .info-container .card .card-text .table .row {
  display: flex;
  margin-top: 10px;
}

.content .info-container .card .card-text .table .row .column {
  font-family: NanumBarunGothic;
  color: #ffffff;
}

.content .info-container .card .card-text .table .row .column:first-child {
  width: 60%;
}

.content .info-container .card .card-text .table .row .column .label {
  font-size: 18px;
}

.content .info-container .card .card-text .table .row .column .text {
  margin-left: 17px;
  font-size: 18px;
  font-weight: 300;
}

.content .map {
  width: 50%;
  height: 424px;
  border: 1px solid #fff;
}

.footer-wrapper {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 113px;
}

#map {
  width: 50%;
  height: 424px;
}

</style>