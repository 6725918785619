<template>
  <div>
    <div class="banner">
      <div class="title-wrapper">
        <div class="title">Contact</div>
      </div>
    </div>
    <div class="container-wrapper">
      <div class="container">
        <div class="section">
          <div class="sub-title"><span>공지사항</span></div>
          <div class="content-wrapper">
            <div class="content" v-if="notice">
              <div class="notice-info">
                <table>
                  <tr>
                    <td class="label" width="10%">제목</td>
                    <td class="text" colspan="5">{{ notice.title }}</td>
                  </tr>
                  <tr>
                    <td class="label" width="10%">등록일</td>
                    <td class="text" width="25%">{{ getDate(notice.regdate) }}</td>
                    <td class="label" width="10%">등록자</td>
                    <td class="text" width="25%">관리자</td>
                    <td class="label" width="10%">조회수</td>
                    <td class="text" width="20%">{{ notice.hit }}</td>
                  </tr>
                  <tr>
                    <td class="label" width="10%">첨부파일</td>
                    <td colspan="5">
                      <span
                        v-for="(file, idx) in notice.files"
                        :key="`f-${idx}`"
                        class="file-label"
                        @click="download(file.filename, file.originalname)"
                      >
                        {{ file.originalname }}
                      </span>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="notice-content" v-html="notice.content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {  
  data() {
    return {      
      notice: null
    }
  },
  created() {
    this.getItem()
  },
  methods: {    
    getDate(dateStr) {
      const regDate = new Date(dateStr)

      let year = regDate.getFullYear()
      let month = regDate.getMonth() + 1
      month = month >= 10 ? month : '0' + month
      let date = regDate.getDate() + 1
      date = date >= 10 ? date : '0' + date
      
      return year + '-' + month + '-' + date
    },
    getItem() {
      this.$axios.get('/api/notice/' + this.$route.params.id)
      .then(res => {
        this.notice = res.data.data.result
        if (!this.notice) {
          alert('공지사항이 존재하지 않습니다.')
          this.$router.replace({ path: '/notice' })
        } 
      })
      .catch(err => {
        console.log(err)
      })
    },
    download(filename, originalname) {
      this.$axios({
        url: '/api/notice/file/' + filename,
        method: 'get',
        responseType: 'blob'
      })
      .then(response => {
        if (response.status === 200 || response.status === 304) {
          const blob = new Blob([response.data])

          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, originalname);
          } else {
            const link = document.createElement('a')
            const url = window.URL.createObjectURL(blob);
            link.href = url;
            link.setAttribute('download', originalname)
            link.setAttribute('type', 'mime-type')
            document.body.appendChild(link)
            link.click()
          }
        } else {
          alert('다운로드 실패')
        }
      })
      .catch(err => {
        console.log(err)
        alert('다운로드 중 오류가 발생하였습니다.')
      })
    },
  }
}
</script>

<style scoped>

.banner {
  width: 100%;
  min-width: 1192px;
  height: 145px;
  margin: 0 auto;
  background-image: url('~@/assets/img/banner/banner_company.png');
}

.title-wrapper {
  position: relative;
  width: 1192px;
  height: 100%;
  margin: 0 auto;
  font-family: NanumBarunGothic;
  font-size: 28px;
  font-weight: 700;
  color: #ffffff;
}

.title-wrapper .title {
  position: absolute;
  left: 0;
  bottom: 20px;
}

.container-wrapper {
  width: 100%;
}

.container {
  width: 1192px;
  margin: 0 auto;
}

.section {
  padding-top: 46px;
  margin-bottom: 114px;
}

.section .sub-title {
  font-family: NanumBarunGothic;
  font-size: 24px;
  color: #383838;
}

.section .sub-title span {
  font-weight: 700;
}

.content-wrapper {
  margin-top: 40px;
}

.content-wrapper .content {
  width: 100%;
}

.content .notice-info {
  width: 100%;
}

table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #DDDDDD;
}

tr {
  height: 50px;
}

td {
  font-family: NanumBarunGothic;
  border: 1px solid #DDDDDD;
}

td.label {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  background-color: #ECECEC;
}

td.text {
  padding-left: 10px;
  font-weight: 300;
}

.notice-content {
  margin-top: 20px;
  padding: 20px;
}

.file-label {
  margin-left: 20px;
  font-family: NanumBarunGothic;
  font-size: 14px;
  font-weight: 500;
  color: #313e77;
  cursor: pointer;
}

</style>