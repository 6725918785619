<template>
  <div>
    <div class="banner">
      <div class="title-wrapper">
        <div class="title">Validation</div>
      </div>
    </div>
    <div class="container-wrapper">
      <div class="container">
        <div class="section">
          <div class="sub-title"><span>시스템/장비 적격성 평가 </span>(System/Equipment Qualification)</div> 
          <div class="content-wrapper">
            <div class="card-container">
              <div class="card">
                <div class="card-title">신축 GMP제조소에 대한 종합 GMP 밸리데이션을 수행하고 있으며<br />제형별 밸리데이션 사업군은 아래와 같습니다.</div>
                <div class="space">
                  <img src="@/assets/img/icon/ellipse.png" />
                </div>
                <div class="card-text">
                  <ul>
                    <li>
                      <span class="number">01</span>
                      <span class="text">내용고형제</span>
                    </li>
                    <li>
                      <span class="number">02</span>
                      <span class="text">내용액제</span>
                    </li>
                    <li>
                      <span class="number">03</span>
                      <span class="text">주사제</span>
                    </li>
                    <li>
                      <span class="number">04</span>
                      <span class="text">연고제</span>
                    </li>
                    <li>
                      <span class="number">05</span>
                      <span class="text">항암제</span>
                    </li>
                    <li>
                      <span class="number">06</span>
                      <span class="text">원료의약품</span><span class="text light">(합성, 발효, 추출)</span><span class="text">전문</span>
                    </li>
                    <li>
                      <span class="number">07</span>
                      <span class="text">생산의약품</span><span class="text light">(백신)</span>
                    </li>
                    <li>
                      <span class="number">08</span>
                      <span class="text">세포치료제 전문</span><span class="text light">(GMP제조소 구축을 포함한 컨설팅)</span>
                    </li>
                    <li>
                      <span class="number">09</span>
                      <span class="text">건강기능식품</span>
                    </li>
                    <li>
                      <span class="number">10</span>
                      <span class="text">의료기기</span>
                    </li>
                    <li>
                      <span class="number">11</span>
                      <span class="text">화장품 등 CGMP제조소</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card light">
                <div class="card-title">신축 GMP제조소에 대한 종합 GMP컨설팅 및 밸리데이션을 수행하고 있으며<br />대상시스템의 유형별 밸리데이션 Service를 제공하고 있습니다.</div>
                <div class="space">
                  <img src="@/assets/img/icon/ellipse_light.png" />
                </div>
                <div class="card-text">
                  <ul>
                    <li>
                      <span class="label">대상시스템</span>
                    </li>
                    <li>
                      <span class="number">01</span>
                      <span class="text">생산장비</span>
                    </li>
                    <li>
                      <span class="number">02</span>
                      <span class="text">분석장비</span>
                    </li>
                    <li>
                      <span class="number">03</span>
                      <span class="text">제조지원설비</span>
                    </li>
                  </ul>
                  <div class="h-line"></div>
                  <ul>
                    <li>
                      <span class="label">산출물</span>
                    </li>
                    <li>
                      <span class="number">01</span>
                      <span class="text">URS Development</span><span class="text light">(Quality Risk Management(QRM))</span>
                    </li>
                    <li>
                      <span class="number">02</span>
                      <span class="text">Basic/ Detail Design Enhanced Review</span><span class="text light">(FDS)</span>
                    </li>
                    <li>
                      <span class="number">03</span>
                      <span class="text">Design Qualification Protocol, Execution and Report</span>
                    </li>
                    <li>
                      <span class="number">04</span>
                      <span class="text">Installation Qualification Protocol, Execution and Report</span>
                    </li>
                    <li>
                      <span class="number">05</span>
                      <span class="text">Operational Qualification Protocol, Execution and Report</span>
                    </li>
                    <li>
                      <span class="number">06</span>
                      <span class="text">Performance Qualification Protocol, Execution and Report</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="btn-wrapper">
            <a class="btn" href="/files/시스템장비적격성_평가_주요수행실적.xlsx" download>
              <img class="btn" src="@/assets/img/button/btn_download.png" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      itemList_1: [
        {
          number: '01',
          label: '내용 고형제'
        },
        {
          number: '02',
          label: '내용액제'
        },
        {
          number: '03',
          label: '주사제'
        },
        {
          number: '04',
          label: '연고제'
        },
        {
          number: '05',
          label: '항암제'
        },
        {
          number: '06',
          label: '원료의약품(합성, 발효, 추출)_전문'
        },
        {
          number: '07',
          label: '내용 고형제'
        },
        {
          number: '08',
          label: '내용 고형제'
        },
        {
          number: '09',
          label: '내용 고형제'
        },
        {
          number: '10',
          label: '내용 고형제'
        },
        {
          number: '11',
          label: '내용 고형제'
        }
      ]
    }
  }
}
</script>

<style scoped>

.banner {
  width: 100%;
  min-width: 1192px;
  height: 145px;
  margin: 0 auto;
  background-image: url('~@/assets/img/banner/banner_company.png');
}

.title-wrapper {
  position: relative;
  width: 1192px;
  height: 100%;
  margin: 0 auto;
  font-family: NanumBarunGothic;
  font-size: 28px;
  font-weight: 700;
  color: #ffffff;
}

.title-wrapper .title {
  position: absolute;
  left: 0;
  bottom: 20px;
}

.container-wrapper {
  width: 100%;
}

.container {
  width: 1192px;
  margin: 0 auto;
}

.section {
  padding-top: 46px;
  margin-bottom: 114px;
}

.section .sub-title {
  font-family: NanumBarunGothic;
  font-size: 24px;
  color: #383838;
}

.section .sub-title span {
  font-weight: 700;
}

.section .btn-wrapper {
  width: 100%;
  overflow: auto;
}

.section .btn-wrapper a img.btn {
  float: right;
  margin-right: 12px;
  cursor: pointer;
  background-image: url('~@/assets/img/button/btn_download.png');

}


.content-wrapper {
  margin-top: 35px;
}

.content-wrapper .card-container {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}

.content-wrapper .card-container .card {
  width: 580px;
}

.content-wrapper .card-container .card .card-title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 88px;
  border-radius: 8px;
  background-image: linear-gradient(to top, #293258, #313e77, #293258);
  font-family: NanumBarunGothic;
  font-size: 17px;  
  line-height: 1.34;
  letter-spacing: -1px;
  text-align: center;
  color: #ffffff;
  z-index: 5;
}

.content-wrapper .card-container .card.light .card-title {
  background-image: linear-gradient(to top, #414f92, #6072c8, #414f92);
}

.content-wrapper .card-container .card .space {
  position: relative;
  width: 100%;
  height: 24px;
  z-index: 1;
}

.content-wrapper .card-container .card .space img {
  position: absolute;
  top: -9px;
  left: 50%;
}

.content-wrapper .card-container .card .card-text {
  width: 537px;
  height: 425px;
  padding: 24px;
  z-index: 0;
  background-repeat: no-repeat;
  background-size: contain;
}

.content-wrapper .card-container .card:nth-child(1) .card-text {
  background-image: url('~@/assets/img/content/validation/ct_evaluation_1.png');
}

.content-wrapper .card-container .card:nth-child(2) .card-text {
  background-image: url('~@/assets/img/content/validation/ct_evaluation_2.png');
}

.content-wrapper .card-container .card .card-text img {
  width: 100%;
  z-index: 0;
}

.content-wrapper .card-container .card .card-text ul {
  list-style-type: none;
}

.content-wrapper .card-container .card .card-text ul li::before {
  content: "";
  display: inline-block; 
  margin-left: -2em;
}

.content-wrapper .card-container .card .card-text ul li:nth-child(n+2) {
  margin-top: 7px;
}

.content-wrapper .card-container .card .card-text ul li .number {
  padding-right: 3px;
  font-family: NanumBarunGothic;
  font-weight: 700;
  font-size: 17px;
  color: #313e77;
}

.content-wrapper .card-container .card.light .card-text ul li .number {
  color: #6072c8;
}

.content-wrapper .card-container .card.light .card-text ul li .label {
  font-size: 18px;
  font-weight: 700;
  color: #6072c8;
  margin-bottom: 7px;
}

.content-wrapper .card-container .card .card-text ul li .text {
  font-family: NanumBarunGothic;
  font-size: 17px;
  color: #383838;
}

.content-wrapper .card-container .card .card-text ul li .text.light {
  font-weight: 300;
}

.content-wrapper .card-container .card .card-text .h-line {
  width: 98%;
  margin: 15px auto 12px auto;
  border-top: 1px solid #afafaf;
  opacity: 0.38;
}

</style>