<template>
  <div>
    <div class="banner">
      <div class="title-wrapper">
        <div class="title">Contact</div>
      </div>
    </div>
    <div class="container-wrapper">
      <div class="container">
        <div class="section">
          <div class="sub-title"><span>담당자</span></div>
          <div class="content-wrapper">
            <div class="card-container">
              <div class="card">
                <div class="card-title">GMP 컨설팅, 밸리데이션</div>
                <div class="card-text">
                  <ul>
                    <li>
                      <span class="label">담당자</span>
                      <span class="bold">김성학</span>
                      <span>수석</span>
                    </li>
                    <li>
                      <span class="label">E-Mail</span>
                      <span>shkim@agathon.co.kr</span>
                    </li>
                    <li>
                      <span class="label">TEL</span>
                      <span>031-831-5778</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card light">
                <div class="card-title">공정제어, 자동화 시스템</div>
                <div class="card-text">
                  <ul>
                    <li>
                      <span class="label">담당자</span>
                      <span class="bold">신형호</span>
                      <span>이사</span>
                    </li>
                    <li>
                      <span class="label">E-Mail</span>
                      <span>hhshin@agathon.co.kr</span>
                    </li>
                    <li>
                      <span class="label">TEL</span>
                      <span>031-831-5778</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card">
                <div class="card-title">컴퓨터 시스템 밸리데이션, 정기검토</div>
                <div class="card-text">
                  <ul>
                    <li>
                      <span class="label">담당자</span>
                      <span class="bold">김준범</span>
                      <span>수석</span>
                    </li>
                    <li>
                      <span class="label">E-Mail</span>
                      <span>jbkim0245@agathon.co.kr</span>
                    </li>
                    <li>
                      <span class="label">TEL</span>
                      <span>031-831-5778</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="table-wrapper">
              <table>
                <tr class="selector">
                  <td colspan="3">
                    <div class="label">Contact form</div>
                    <div class="select-container">
                      <ul>
                        <li
                          v-for="(item, idx) in items"
                          :key="`d-${idx}`"
                          @click="selectDepartment(item)"
                          :class="{ active: selectedDepartment === item }"
                        >
                          {{ item.label }}
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="label">Name*</span>
                    <input type="text" ref="name" v-model="info.name" />
                  </td>
                  <td>
                    <span class="label">Company*</span>
                    <input type="text" ref="company" v-model="info.company" />
                  </td>
                  <td>
                    <span class="label">E-mail*</span>
                    <input type="text" ref="email" v-model="info.email" placeholder="abcd@email.com" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="label">Phone</span>
                    <input type="text" ref="phone" v-model="info.phone" placeholder="000-0000-0000" />
                  </td>
                  <td>
                    <span class="label">Title</span>
                    <input type="text" v-model="info.title" />
                  </td>
                  <td>
                    <span class="label">Address</span>
                    <input type="text" v-model="info.address" />
                  </td>
                </tr>
                <tr>
                  <td class="label-wrapper" colspan="3">
                    <span class="label">Message*</span>
                  </td>
                </tr>
                <tr class="textarea">
                  <td colspan="3">
                    <div class="text">
                      <textarea ref="message" v-model="info.message"></textarea>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <div class="btn-wrapper">
              <div class="btn" @click="sendEmail()">SEND</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'main',
  data() {
    return {
      map: null,

      items: [
        {
          label: '밸리데이션 사업부',
          email: 'shkim@agathon.co.kr'
        },
        {
          label: '시스템 사업부',
          email: 'hhshin@agathon.co.kr'
        },
        {
          label: '컴퓨터 시스템 밸리데이션 사업부 ',
          email: 'jbkim0245@agathon.co.kr'
        }
      ],

      info: {
        name: '',
        company: '',
        email: '',
        phone: '',
        title: '',
        address: '',
        message: ''
      },

      selectedDepartment: null
    }
  },
  methods: {
    selectDepartment(department) {
      this.selectedDepartment = department
    },
    sendEmail() {
      if (!this.validate()) return

      this.info.target = this.selectedDepartment.email

      this.$axios.post('/api/sendmail', this.info)
      .then(res => {
        console.log(res.status)
        alert('이메일이 전송되었습니다.')
        this.reset()
      })
    },
    validate() {
      let isValid = true

      if (!this.selectedDepartment) {
        alert('부서가 선택되지 않았습니다.')
        isValid = false
      } else if (!this.info.name || this.info.name.trim() === '') { // 필수 입력 항목 공백 확인
        alert('성함을 입력해주세요.')
        this.info.name = ''
        this.$refs.name.focus()
        isValid = false
      } else if (!this.info.company || this.info.company.trim() === '') {
        alert('회사명을 입력해주세요.')
        this.info.company = ''
        this.$refs.company.focus()
        isValid = false
      } else if (!this.info.email || this.info.email.trim() === '') {
        alert('이메일 주소를 입력해주세요.')
        this.info.email = ''
        this.$refs.email.focus()
        isValid = false
      } else if (!this.info.message || this.info.message.trim() === '') {
        alert('메시지를 입력해주세요.')
        this.info.message = ''
        this.$refs.message.focus()
        isValid = false
      } else {
        // 이메일 유효성 검사
        const regEmail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i
        if (!regEmail.test(this.info.email)) {
          alert('이메일 주소를 입력해주세요.')
          this.info.email = ''
          this.$refs.email.focus()
          isValid = false
        }

        const regMobile = /^\d{3}-\d{3,4}-\d{4}$/
        const regPhone = /^\d{2,3}-\d{3,4}-\d{4}$/

        if (!!this.info.phone && (!regMobile.test(this.info.phone) || !regPhone.test(this.info.phone))) {
          alert('형식에 맞는 연락처를 입력해주세요.')
          this.info.phone = ''
          this.$refs.phone.focus()
          isValid = false
        }
      }

      return isValid
    },
    reset() {
      for (let key in this.info) {
        if (this.info.hasOwnProperty(key)) {
          this.info[key] = ''
        }
      }

      this.selectDepartment(this.items[0])
    }
  },
  created() {
    this.selectDepartment(this.items[0])
  }
}
</script>

<style scoped>

.banner {
  width: 100%;
  min-width: 1192px;
  height: 145px;
  margin: 0 auto;
  background-image: url('~@/assets/img/banner/banner_company.png');
}

.title-wrapper {
  position: relative;
  width: 1192px;
  height: 100%;
  margin: 0 auto;
  font-family: NanumBarunGothic;
  font-size: 28px;
  font-weight: 700;
  color: #ffffff;
}

.title-wrapper .title {
  position: absolute;
  left: 0;
  bottom: 20px;
}

.container-wrapper {
  width: 100%;
}

.container {
  width: 1192px;
  margin: 0 auto;
}

.section {
  padding-top: 46px;
  margin-bottom: 114px;
}

.section .sub-title {
  font-family: NanumBarunGothic;
  font-size: 24px;
  color: #383838;
}

.section .sub-title span {
  font-weight: 700;
}

.content-wrapper {
  margin-top: 40px;
}

.content-wrapper .content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.content-wrapper .card-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
}

.content-wrapper .card-container .card {

}

.content-wrapper .card-container .card .card-title {
  width: 390px;
  height: 42px;
  line-height: 42px;
  background-image: linear-gradient(to top, #293258, #313e77, #293258);
  font-family: NanumBarunGothic;
  font-size: 20px;
  font-weight: 700;  
  text-align: center;
  color: #ffffff;
}

.content-wrapper .card-container .card.light .card-title {
  background-image: linear-gradient(to top, #414f92, #6072c8, #414f92);
}

.content-wrapper .card-container .card .card-text {
  padding: 14px;
}

.content-wrapper .card-container .card .card-text ul {
  list-style: none;
}

.content-wrapper .card-container .card .card-text ul li {
  font-family: NanumBarunGothic;
  font-size: 18px;
  line-height: 1.5;
  color: #363636;
}

.content-wrapper .card-container .card .card-text ul li::before {
  content: "\2022";
  color: #313e77;
  display: inline-block; 
  width: 0.8em;
  margin-left: -2em;
}

.content-wrapper .card-container .card .card-text ul li span.label {
  margin-right: 10px;
  font-weight: 700;
  color: #313e77;
}

.content-wrapper .card-container .card .card-text ul li span.bold {
  font-weight: 700;
}

.content-wrapper .table-wrapper {
  margin-top: 15px;
}

input, textarea {
  border: 0;
}

input:focus, textarea:focus {
  outline: 1px solid #b9b9b9;
}

.table-wrapper table {
  width: 100%;
  border-collapse: collapse;
}

.table-wrapper table, th, td {
  border: 1px solid #b9b9b9;
}

.table-wrapper table, tr {
  height: 46px;
}

.table-wrapper table tr td {
  padding: 0 12px;
}

.table-wrapper table tr td .label {
  font-family: NanumBarunGothic;
  font-size: 18px;
  color: #313e77;
}

.table-wrapper table tr td input {
  height: 30px;
}

.table-wrapper table tr.selector {
  background-color: rgba(185, 185, 185, 0.26);
}

.table-wrapper table tr.selector td {
  font-family: NanumBarunGothic;
  font-size: 20px;
  font-weight: 700;
  color: #313e77;
}

.table-wrapper table tr.selector td .label {
  float: left;
}

.table-wrapper table tr.selector td .select-container {
  float: right;
}

.table-wrapper table tr.selector td .select-container ul {
  
}

.table-wrapper table tr.selector td .select-container ul li {
  float: left;
  margin-left: 70px;
  font-family: NanumBarunGothic;
  font-size: 20px;
  font-weight: 700;
  color: #9e9e9e;
  cursor: pointer;
}

.table-wrapper table tr.selector td .select-container ul li.active {
  color: #313e77;
}

.table-wrapper table tr td.label-wrapper {
  border-bottom: none;
}

.table-wrapper table tr.textarea td {
  border-top: none;
}

.table-wrapper table tr.textarea td textarea {
  width: 100%;
  height: 142px;
}

.content-wrapper .btn-wrapper {
  width: 100%;
  margin-top: 18px;
  overflow: auto;
}

.content-wrapper .btn-wrapper .btn {
  float: right;
  width: 134px;
  height: 31px;
  line-height: 31px;
  border-radius: 15.5px;
  background-color: #313e77;
  font-family: NanumBarunGothic;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

#map {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 35%;
  margin: auto;
}

input {
  width: 200px;
  margin-left: 5px;
  padding: 0 5px;
  font-size: 15px;
}

</style>