<template>
  <div>
    <div class="banner">
      <div class="title-wrapper">
        <div class="title">Validation</div>
      </div>
    </div>
    <div class="container-wrapper">
      <div class="container">
        <div class="section">
          <div class="sub-title"><span>컴퓨터 시스템 밸리데이션</span>(Computer System Validation)</div>
          <div class="content-wrapper">
            <div class="explanation"><span>AGATHON</span>은 컴퓨터 시스템 전 생애 주기 동안의 체계적이고 효율적인 밸리데이션 서비스를<br />제공함으로써 시스템 초기 구축 단계 뿐만 아니라 운영단계까지 지속적인 밸리데이션 상태를 유지할 수 있도록 지원하고 있습니다.</div>
            <div class="image-wrapper">
              <img src="@/assets/img/content/validation/ct_csv.png" />
            </div>
            <div class="content-container">
              <div class="content">
                <div class="subject">서비스 영역</div>
                <img src="@/assets/img/content/validation/ct_csv_2.png" />
              </div>
              <div class="content">
                <div class="subject">서비스 대상</div>
                <img src="@/assets/img/content/validation/ct_csv_3.png" />
              </div>
            </div>
          </div>
        </div>

        <div class="section">
          <div class="sub-title"><span>비즈니스 프레임워크</span>(Business Framework)</div>
          <div class="content-wrapper">
            <div class="explanation"><span>AGATHON</span>은 고객사 내 구축/운영 중인 컴퓨터 시스템에 대한 inventory 조사, 시스템 영향 평가와 GAP 분석을 순차적으로 진행함으로써, 대상 시스템에 대한 컴퓨터 시스템 밸리데이션 수행 근거 마련 및 필요 보완 사항을 도출하여  최종적으로 GMP 규제 기준에 부합할 수 있도록 지원해 드립니다.</div>
            <div class="image-wrapper">
              <img src="@/assets/img/content/validation/ct_csv_4.png" />
            </div>            
          </div>
        </div>

        <div class="section">
          <div class="sub-title"><span>정보시스템</span>(IT)<span>/자동화시스템/실험실 컴퓨터시스템 밸리데이션 </span>(Computer system validation)</div>
          <div class="content-wrapper">
            <div class="image-wrapper">
              <img src="@/assets/img/content/validation/ct_csv_5.png" />
            </div>            
          </div>
          <div class="btn-wrapper">
            <a class="btn" href="/files/컴퓨터시스템_밸리데이션_주요수행실적.xlsx" download>
              <img class="btn" src="@/assets/img/button/btn_download.png" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'main'
}
</script>

<style scoped>

.banner {
  width: 100%;
  min-width: 1192px;
  height: 145px;
  margin: 0 auto;
  background-image: url('~@/assets/img/banner/banner_company.png');
}

.title-wrapper {
  position: relative;
  width: 1192px;
  height: 100%;
  margin: 0 auto;
  font-family: NanumBarunGothic;
  font-size: 28px;
  font-weight: 700;
  color: #ffffff;
}

.title-wrapper .title {
  position: absolute;
  left: 0;
  bottom: 20px;
}

.container-wrapper {
  width: 100%;
}

.container {
  width: 1192px;
  margin: 0 auto;
}

.section {
  padding-top: 46px;
  margin-bottom: 114px;
}

.section .sub-title {
  font-family: NanumBarunGothic;
  font-size: 24px;
  color: #383838;
}

.section .sub-title span {
  font-weight: 700;
}

.section .btn-wrapper {
  width: 100%;
  margin-top: 25px;
  overflow: auto;
}

.section .btn-wrapper img.btn {
  float: right;
  margin-right: 12px;
  cursor: pointer;
}

.content-wrapper {
  margin-top: 18px;
}

.content-wrapper .explanation {
  font-family: NanumBarunGothic;
  font-size: 17px;
  color: #383838;
  letter-spacing: -1px;
  line-height: 1.46;
}

.content-wrapper .explanation span {
  font-size: 22px;
  font-weight: 700;
  color: #313e77;
}

.content-wrapper .image-wrapper {
  display: flex;
  width: 100%;
  margin-top: 28px;
}

.content-wrapper .image-wrapper img {
  width: 100%;
}

.content-wrapper .content-container {
  margin-top: 33px;
}

.content-wrapper .content-container .content {
  margin-top: 62px;
}

.content-wrapper .content-container .content .subject {
  font-family: NanumBarunGothic;
  font-size: 22px;
  font-weight: 700;
  color: #313e77;
}

.content-wrapper .content-container .content img {
  margin-top: 25px;
}

</style>