<template>
  <div>
    <div class="banner">
      <div class="title-wrapper">
        <div class="title">GMP Consulting</div>
      </div>
    </div>
    <div class="container-wrapper">
      <div class="container">
        <div class="section">
          <div class="sub-title"><span>사전 실사 </span>(Mock Inspection)</div>
          <div class="content-wrapper">
            <div class="explanation">애거슨은 풍부한 국내/해외 GMP감사 대응 경험을 보유 하고 있습니다. 이를 바탕으로 의약품, 의료기기, 화장품, 건강식품 등<br />GMP를 운영하는 기업을 대상으로 규제기관의 실사 대응을 위한 사전 모의 실사 프로그램을 운영하고 있습니다.</div>
            <div class="detail">
              <div class="subject"><span>업무수행 절차</span>(Procedures)</div>
              <div class="image-wrapper">
                <img src="@/assets/img/content/consulting/ct_procedure.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'main'
}
</script>

<style scoped>

.banner {
  width: 100%;
  min-width: 1192px;
  height: 145px;
  margin: 0 auto;
  background-image: url('~@/assets/img/banner/banner_company.png');
}

.title-wrapper {
  position: relative;
  width: 1192px;
  height: 100%;
  margin: 0 auto;
  font-family: NanumBarunGothic;
  font-size: 28px;
  font-weight: 700;
  color: #ffffff;
}

.title-wrapper .title {
  position: absolute;
  left: 0;
  bottom: 20px;
}

.container-wrapper {
  width: 100%;
}

.container {
  width: 1192px;
  margin: 0 auto;
}

.section {
  padding-top: 46px;
  margin-bottom: 114px;
}

.section .sub-title {
  font-family: NanumBarunGothic;
  font-size: 24px;
  color: #383838;
}

.section .sub-title span {
  font-weight: 700;
}

.content-wrapper {
  margin-top: 18px;
}

.content-wrapper .explanation {
  font-family: NanumBarunGothic;
  font-size: 17px;
  line-height: 1.63;
  color: #383838;
}

.content-wrapper .detail {
  width: 100%;
  margin-top: 77px;
}

.content-wrapper .detail .subject {
  font-family: NanumBarunGothic;
  font-size: 17px;
  text-align: left;
  color: #313e77;
}

.content-wrapper .detail .subject span {
  font-weight: 700;
}

.content-wrapper .detail .image-wrapper {
  display: flex;
  width: 100%;
  margin-top: 34px;
}

.content-wrapper .detail .image-wrapper img {
  margin: 0 auto;
}

</style>