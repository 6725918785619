<template>
  <div>
    <div class="banner">
      <div class="title-wrapper">
        <div class="title">Contact</div>
      </div>
    </div>
    <div class="container-wrapper">
      <div class="container">
        <div class="section">
          <div class="sub-title"><span>공지사항</span></div>
          <div class="content-wrapper">
            <div class="content">
              <table>
                <thead>
                  <th width="8%">번호</th>
                  <th width="60%">제목</th>
                  <th width="10%">작성자</th>
                  <th width="12%">작성일</th>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, idx) in items"
                    :key="`n-${idx}`"
                  >
                    <td align="center">{{ idx+1 }}</td>
                    <td class="pointer" @click="noticePage(item.id)">{{ item.title }}</td>
                    <td align="center">관리자</td>
                    <td align="center">{{ getDate(item.regdate) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default { 
  data() {
    return {      
      items: []
    }
  },
  created() {
    this.getItems()
  },
  methods: {
    getDate(dateStr) {
      const regDate = new Date(dateStr)

      let year = regDate.getFullYear()
      let month = regDate.getMonth() + 1
      month = month >= 10 ? month : '0' + month
      let date = regDate.getDate() + 1
      date = date >= 10 ? date : '0' + date
      
      return year + '-' + month + '-' + date
    },
    noticePage(id) {
      this.$router.push({ path: './notice/' + id })
    },
    getItems() {
      this.$axios.get('/api/notice?_=' + new Date().getTime())
      .then(res => {
        this.items = res.data.data.result
      })
      .catch(err => {
        console.log(err)
      })
    },
  }
}
</script>

<style scoped>

.banner {
  width: 100%;
  min-width: 1192px;
  height: 145px;
  margin: 0 auto;
  background-image: url('~@/assets/img/banner/banner_company.png');
}

.title-wrapper {
  position: relative;
  width: 1192px;
  height: 100%;
  margin: 0 auto;
  font-family: NanumBarunGothic;
  font-size: 28px;
  font-weight: 700;
  color: #ffffff;
}

.title-wrapper .title {
  position: absolute;
  left: 0;
  bottom: 20px;
}

.container-wrapper {
  width: 100%;
}

.container {
  width: 1192px;
  margin: 0 auto;
}

.section {
  padding-top: 46px;
  margin-bottom: 114px;
}

.section .sub-title {
  font-family: NanumBarunGothic;
  font-size: 24px;
  color: #383838;
}

.section .sub-title span {
  font-weight: 700;
}

.content-wrapper {
  margin-top: 40px;
}

.content-wrapper .content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

table {
  width: 100%;
  min-height: 20px;
  border-collapse: collapse;
  border: 0;
  border-top: 1px solid#40538A;
  border-bottom: 1px solid#40538A;
}

thead {
  border-bottom: 1px solid#40538A;
}

th {
  height: 35px;
  color: #40538A;
}

tr {
  height: 40px;
  border-bottom: 1px solid #D4D4D4;
}

td {
  font-family: NanumBarunGothic;
}

td.pointer {
  cursor: pointer;
}

</style>