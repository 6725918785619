<template>
  <div>
    <div class="banner">
      <div class="title-wrapper">
        <div class="title">Company</div>
      </div>
    </div>
    <div class="container-wrapper">
      <div class="container">
        <div class="section">
          <div class="sub-title"><span>연혁</span> (History)</div>
          <div class="content-wrapper">
            <div class="line-vertical"></div>
            <div class="item-list">
              <div class="year">2019</div>
              <img src="@/assets/img/icon/line_circle.png" />
              <div class="text-container">
                <div class="text"><div class="month">05월</div>㈜애거슨&amp;J2H 바이오텍 합작 법인 설립“㈜애거슨바이오”</div>
              </div>
            </div>
            <div class="item-list">
              <div class="year">2015</div>
              <img src="@/assets/img/icon/line_circle.png" />
              <div class="text-container">
                <div class="text"><div class="month">07월</div>민관합동 스마트공장 추진단 협력서 등록</div>
              </div>
            </div>
            <div class="item-list">
              <div class="year">2013</div>
              <img src="@/assets/img/icon/line_circle.png" />
              <div class="text-container">
                <div class="text"><div class="month">03월</div>Rockwell Automation(USA) 파트너 체결</div>
                <div class="text"><div class="month">01월</div>동탄 일반산업단지 신축 사옥 준공 이전</div>
              </div>
            </div>
            <div class="item-list">
              <div class="year">2011</div>
              <img src="@/assets/img/icon/line_circle.png" />
              <div class="text-container">
                <div class="text"><div class="month">07월</div>기술혁신 중소기업 INO-BIZ 획득</div>
              </div>
            </div>
            <div class="item-list">
              <div class="year">2010</div>
              <img src="@/assets/img/icon/line_circle.png" />
              <div class="text-container">
                <div class="text"><div class="month">04월</div>애거슨(AGATHON)으로 상호 변경</div>
              </div>
            </div>
            <div class="item-list">
              <div class="year">2009</div>
              <img src="@/assets/img/icon/line_circle.png" />
              <div class="text-container">
                <div class="text"><div class="month">10월</div>기업부설연구소 설립</div>
                <div class="text"><div class="month">09월</div>Venture 인증 취득</div>
                <div class="text"><div class="month">03월</div>전문 건설업 등록(기계설비 공사 업 면허 취득)</div>
              </div>
            </div>
            <div class="item-list">
              <div class="year">2008</div>
              <img src="@/assets/img/icon/line_circle.png" />
              <div class="text-container">
                <div class="text"><div class="month">10월</div>환경경영시스템(ISO14001) 인증 등록</div>
                <div class="text"><div class="month"></div>품질경영시스템(ISO9001) 인증 등록</div>
              </div>
            </div>
            <div class="item-list">
              <div class="year">2006</div>
              <img src="@/assets/img/icon/line_circle.png" />
              <div class="text-container">
                <div class="text"><div class="month">09월</div>애거슨 앤 써파마(Agathon&amp;Seerpharma) 설립</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

.banner {
  width: 100%;
  min-width: 1192px;
  height: 145px;
  margin: 0 auto;
  background-image: url('~@/assets/img/banner/banner_company.png');
}

.title-wrapper {
  position: relative;
  width: 1192px;
  height: 100%;
  margin: 0 auto;
  font-family: NanumBarunGothic;
  font-size: 28px;
  font-weight: 700;
  color: #ffffff;
}

.title {
  position: absolute;
  left: 0;
  bottom: 20px;
}

.container-wrapper {
  width: 100%;
}

.container {
  width: 1192px;
  margin: 0 auto;
}

.section {
  padding-top: 46px;
  margin-bottom: 114px;
}

.section .sub-title {
  font-family: NanumBarunGothic;
  font-size: 24px;
  color: #383838;
}

.section .sub-title span {
  font-weight: 700;
}

.content-wrapper {
  position: relative;
  margin-top: 30px;
}

.line-vertical {
  position: absolute;
  top: 16px;
  left: 110px;
  width: 0px;
  height: 514px;
  border: solid 1px #d7d7d7;
  z-index: -1;
}

.item-list {
  display: flex;
  margin-bottom: 30px;
}

.item-list .year {
  font-family: NanumBarunGothic;
  font-size: 23px;
  font-weight: 700;
  color: #313e77;
}

.item-list img {
  width: 46px;
  height: 18px;
  margin-top: 4px;
  margin-left: 21px;
}

.item-list .text-container {
  margin-left: 58px;
  padding-top: 2px;
}

.item-list .text-container .text {
  font-family: NanumBarunGothic;
  font-size: 19px;
  font-weight: 300;
  color: #383838;
}

.item-list .text-container .text:nth-child(n+2) {
  margin-top: 10px;
}

.item-list .text-container .text span {
  margin-right: 21px;
  font-weight: normal;
}

.item-list .text-container .text .month {
  float: left;
  width: 40px;
  height: 1px;
  margin-right: 21px;
  font-weight: normal;
}

</style>