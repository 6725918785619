<template>
  <div class="container-wrapper">
    <div class="container">
      <div class="logo-wrapper">
        <router-link to="/"><img class="home" src="@/assets/img/logo/logo.png" /></router-link>
      </div>
      <div class="menu-wrapper">
        <div class="menu-container">
          <ul>
            <li
              v-for="(menu, idx) in menuList"
              :key="`m-${idx}`"
              :ref="`menu_${idx}`"
              @mouseover="menuMouseOver(menu)"
            >
              <a href="javascript:void(0);">{{ menu.label }}</a>
              <template v-if="currentMenu !== null">
                <div class="submenu-wrapper">
                  <div class="submenu-line">
                    <div class="line"></div>
                  </div>
                </div>
                <div class="submenu">
                  <div class="list-wrapper">
                      <div class="list">
                      <ul>
                        <li
                          v-for="(submenu, idx) in currentMenu.children"
                          :key="`s-${idx}`"
                        >
                          <a :href="submenu.url">{{ submenu.label }}</a>
                        </li>
                      </ul>
                      </div>
                  </div>
                </div>
              </template>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuList: [
        {
          label: 'COMPANY',
          children: [
            {
              label: '비전/미션',
              url: '/company'
            },
            {
              label: '연혁',
              url: '/company/history'
            },
            {
              label: '조직도',
              url: '/company/organization'
            }
          ]
        },
        {
          label: 'GMP CONSULTING',
          children: [
            {
              label: '개념설계',
              url: '/consulting/design'
            },
            {
              label: '밸리데이션 컨설팅',
              url: '/consulting/validation'
            },
            {
              label: '사전 실사',
              url: '/consulting/inspection'
            },
            {
              label: 'PQS',
              url: '/consulting/pqs'
            },
            {
              label: '정기검토',
              url: '/consulting/review'
            },
            {
              label: 'ISP',
              url: '/consulting/isp'
            }
          ]
        },
        {
          label: 'VALIDATION',
          children: [
            {
              label: '시스템/장비 적격성 평가',
              url: '/validation/evaluation'
            },
            {
              label: '컴퓨터 시스템 밸리데이션',
              url: '/validation/csv'
            }
          ]
        },
        {
          label: 'AUTOMATION SYSTEM',
          children: [
            {
              label: '사업 영역',
              url: '/automation/field'
            },
            {
              label: '산업군별 적용',
              url: '/automation/industry'
            },
            {
              label: '공급 시스템 유형',
              url: '/automation/type'
            },
            {
              label: '컨트롤러 및 솔루션',
              url: '/automation/solution'
            }
          ]
        },
        {
          label: 'CONTACT',
          children: [
            {
              label: '오시는 길',
              url: '/contact/location'
            },
            {
              label: '담당자',
              url: '/contact/manager'
            },
            {
              label: '공지사항',
              url: '/notice'
            }
          ]
        }
      ],

      currentMenu: null,
      subMenuList: null
    }
  },
  created() {
    this.currentMenu = this.menuList[0]
  },
  methods: {
    menuMouseOver(menu) {
      this.currentMenu = menu
    }   
  }
}
</script>

<style scoped>

.container-wrapper {
  position: relative;
}

.container {
  width: 1192px;
  height: 114px;
  margin: 0 auto;
  background-color: #fff;
}

.logo-wrapper {
  display: flex;
  align-items: flex-end;
  padding-bottom: 5px;
  float: left;
  width: 327px;
  height: 109px;
  overflow: auto;
}

.menu-wrapper {
  display: flex;
  justify-content: left;
  align-items: flex-end;
  float: left;
  width: 865px;
  height: 100%;
}

.menu-container {
  
}

.menu-container ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu-container ul li {
  float: left;
}

.menu-container > ul > li {
  position: relative;
  border-bottom: 2px solid transparent;
}

.menu-container > ul > li:hover {
  border-bottom: 2px solid #313e77;
  font-weight: 700;
}

.menu-container ul li:hover .submenu, .menu-container ul li:hover .submenu-wrapper {
  display: block;
}

.menu-container > ul > li > a {
  display: block;
  padding: 13px 30px;
  font-family: NanumBarunGothic;
  font-size: 16px;
  color: #383838;
  text-decoration: none;
}

.menu-container ul li .submenu {
  display: none;
  position: absolute;
  bottom: -35px;
  width: 100%;
  height: 35px;
  background-color: transparent;
  z-index: 2;
}

.menu-container ul li .submenu-wrapper {
  display: none;
  position: absolute;
  bottom: -2px;
  z-index: 1;
}

.menu-container ul li .submenu-wrapper .submenu-line {
  position: relative;
}

.menu-container ul li .submenu-wrapper .submenu-line .line {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 5000px;
  height: 35px;
  background-color: rgba(255, 255, 255, 0.8);
}

.menu-container ul li .submenu .list-wrapper {
  width: 100%;
  position: relative;
}

.menu-container ul li .submenu .list-wrapper .list {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 1200px;
}

.menu-container ul li .submenu .list-wrapper ul {
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.menu-container ul li .submenu .list-wrapper ul li {
  float: left;
  height: 35px;
  line-height: 35px;
  padding: 0 20px;
  display: block;
  text-align: center;
}

.menu-container ul li .submenu .list-wrapper ul li a {
  color: #66686d;
  cursor: pointer;
  text-decoration: none;
}

.menu-container ul li .submenu .list-wrapper ul li a:hover {
  font-weight: 700;
  color: #000;
}

</style>