<template>
  <div>
    <div class="banner">
      <div class="title-wrapper">
        <div class="title">Automation System</div>
      </div>
    </div>
    <div class="container-wrapper">
      <div class="container">
        <div class="section">
          <div class="sub-title"><span>컨트롤러 및 솔루션</span> (Controller &amp; Solution)</div>
          <div class="content-wrapper">
            <div class="table-list">
              <div class="card">
                <div class="card-title">
                  <div class="kr">로크웰 오토메이션</div>
                  <div class="en">ROCKWELL AUTOMATION, USA</div>
                </div>
                <div class="card-text">
                  <div class="item-container">
                    <div class="item-image">
                      <img src="@/assets/img/thumb/automation/tb_rock_1.png" />
                    </div>
                    <div class="item-label vertical">
                      <img src="@/assets/img/thumb/automation/tb_rock_5.png" />
                      <div class="text">SYSTEM<br />INTEGRATOR<br />PARTNER</div>
                    </div>
                  </div>
                  <div class="item-container">
                    <div class="item-image">
                      <img src="@/assets/img/thumb/automation/tb_rock_2.png" />
                    </div>
                    <div class="item-label">Factory<br />Talk<br />(HMI)</div>
                  </div>
                  <div class="item-container">
                    <div class="item-image">
                      <img src="@/assets/img/thumb/automation/tb_rock_3.png" />
                    </div>
                    <div class="item-label">1756 CLX<br />(DCS, PLC)</div>
                  </div>
                  <div class="item-container">
                    <div class="item-image">
                      <img src="@/assets/img/thumb/automation/tb_rock_4.png" />
                    </div>
                    <div class="item-label">1769 CMX<br />(PLC)</div>
                  </div>                  
                </div>
              </div>
              <div class="card">
                <div class="card-title">
                  <div class="kr">지멘스</div>
                  <div class="en">SIEMENS, GER</div>
                </div>
                <div class="card-text">
                  <div class="item-container">
                    <img src="@/assets/img/thumb/automation/tb_siemens_1.png" />
                  </div>
                  <div class="item-container">
                    <div class="item-image">
                      <img src="@/assets/img/thumb/automation/tb_siemens_2.png" />
                    </div>
                    <div class="item-label">WinCC<br />(HMI)</div>
                  </div>
                  <div class="item-container">
                    <div class="item-image">
                      <img src="@/assets/img/thumb/automation/tb_siemens_3.png" />
                    </div>
                    <div class="item-label">S7<br />300/400<br />(PLC)</div>
                  </div>
                  <div class="item-container">
                    <div class="item-image">
                      <img src="@/assets/img/thumb/automation/tb_siemens_4.png" />
                    </div>
                    <div class="item-label">S7<br />1500/1200<br />(PLC)</div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-title">
                  <div class="kr">원더웨어</div>
                  <div class="en">Wonderware, USA</div>
                </div>
                <div class="card-text label-half">
                  <div class="item-container">
                    <img src="@/assets/img/thumb/automation/tb_wonderware_1.png" />
                  </div>
                  <div class="item-container">
                    <div class="item-image">
                      <img src="@/assets/img/thumb/automation/tb_wonderware_2.png" />
                    </div>
                    <div class="item-label">Intouch<br />(HMI)</div>
                  </div>
                  <div class="item-container">
                    <div class="item-image">
                      <img src="@/assets/img/thumb/automation/tb_wonderware_3.png" />
                    </div>
                    <div class="item-label">Historian<br />Server<br />(DATA BASE)</div>
                  </div>
                  <div class="item-container">
                    <div class="item-image">
                      <img src="@/assets/img/thumb/automation/tb_wonderware_4.png" />
                    </div>
                    <div class="item-label">Active<br />Factory<br />(Electric Report)</div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-title">
                  <div class="kr">엘에스 산전</div>
                  <div class="en">LS INDUSTRY, KOR</div>
                </div>
                <div class="card-text label-half">
                  <div class="item-container">
                    <img src="@/assets/img/thumb/automation/tb_ls_1.png" />
                  </div>
                  <div class="item-container">
                    <div class="item-image">
                      <img src="@/assets/img/thumb/automation/tb_ls_2.png" />
                    </div>
                    <div class="item-label">XP PANEL<br />(Touch Panel)</div>
                  </div>
                  <div class="item-container">
                    <div class="item-image">
                      <img src="@/assets/img/thumb/automation/tb_ls_3.png" />
                    </div>
                    <div class="item-label">XGT, XGB<br />XEC<br />(PLC)</div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-title">
                  <div class="kr">싸이먼</div>
                  <div class="en">CIMON, KOR</div>
                </div>
                <div class="card-text label-half">
                  <div class="item-container">
                    <img src="@/assets/img/thumb/automation/tb_cimon_1.png" />
                  </div>
                  <div class="item-container">
                    <div class="item-image">
                      <img src="@/assets/img/thumb/automation/tb_cimon_2.png" />
                    </div>
                    <div class="item-label">CIMON<br />SCADA<br />(HMI)</div>
                  </div>
                  <div class="item-container">
                    <div class="item-image">
                      <img src="@/assets/img/thumb/automation/tb_cimon_3.png" />
                    </div>
                    <div class="item-label">X-PANEL<br />(Touch Panel)</div>
                  </div>
                  <div class="item-container">
                    <div class="item-image">
                      <img src="@/assets/img/thumb/automation/tb_cimon_4.png" />
                    </div>
                    <div class="item-label">CM1/CM3<br />(PLC)</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="btn-wrapper">
            <a class="btn" href="/files/컴퓨터시스템_밸리데이션_주요수행실적.xlsx" download>
              <img class="btn" src="@/assets/img/button/btn_download.png" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'main'
}
</script>

<style scoped>

.banner {
  width: 100%;
  min-width: 1192px;
  height: 145px;
  margin: 0 auto;
  background-image: url('~@/assets/img/banner/banner_company.png');
}

.title-wrapper {
  position: relative;
  width: 1192px;
  height: 100%;
  margin: 0 auto;
  font-family: NanumBarunGothic;
  font-size: 28px;
  font-weight: 700;
  color: #ffffff;
}

.title-wrapper .title {
  position: absolute;
  left: 0;
  bottom: 20px;
}

.container-wrapper {
  width: 100%;
}

.container {
  width: 1192px;
  margin: 0 auto;
}

.section {
  padding-top: 46px;
  margin-bottom: 114px;
}

.section .sub-title {
  font-family: NanumBarunGothic;
  font-size: 24px;
  color: #383838;
}

.section .sub-title span {
  font-weight: 700;
}

.section .btn-wrapper {
  width: 100%;
  margin-top: 25px;
  overflow: auto;
}

.section .btn-wrapper img.btn {
  float: right;
  cursor: pointer;
}

.content-wrapper {
  margin-top: 35px;
}

.subject {
  font-family: NanumBarunGothic;
  font-size: 20px;
  font-weight: 700;  
  color: #383838;
}

.table-list {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.table-list .card {

}

.table-list .card .card-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 233px;
  height: 60px;
  background-image: linear-gradient(to top, #293258, #313e77);
  font-family: NanumBarunGothic;
  color: #fefefe;
  text-align: center;
}

.table-list .card .card-title .kr {
  font-size: 18px;
}

.table-list .card .card-title .en {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 300;
}

.table-list .card .card-text {
  width: 233px;
  height: 450px;
  border: solid 1px #bcbcbc;
}

.table-list .card .card-text .item-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 110px;
}

.table-list .card .card-text .item-container:first-child {
  height: 95px;
}

.table-list .card .card-text .item-container .item-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 145px;
}

.table-list .card .card-text .item-container .item-label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  font-family: NanumBarunGothic;
  font-size: 13px;  
  text-align: center;
  color: #383838;
}

.table-list .card .card-text .item-container .item-label.vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.table-list .card .card-text .item-container .item-label.vertical .text {
  margin-top: 5px;
  font-weight: 700;
}

.table-list .card .card-text .item-container.label-wide .item-image {
  width: 93px;
}

.table-list .card .card-text .item-container.label-wide .item-label {
  width: 140px;
  text-align: left;
}

.table-list .card .card-text.label-half .item-container .item-image {
  width: 125px;
}

.table-list .card .card-text.label-half .item-container .item-label {
  width: 108px;
  text-align: center;
}

</style>