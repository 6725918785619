<template>
  <div>
    <div class="banner">
      <div class="title-wrapper">
        <div class="title">Company</div>
      </div>
    </div>
    <div class="container-wrapper">
      <div class="container">
        <div class="section">
          <div class="sub-title"><span>미션&amp;비젼</span> (Mission&amp;Vision)</div>
          <div class="content-wrapper">
            <div class="brunch left">
              <img class="dot" src="@/assets/img/icon/line_dot.png" />
              <img class="mission" src="@/assets/img/icon/ic_mountain.png" />
              <div class="label"><span>미션</span>MISSION</div>
              <div class="description">인류 건강과 복지 증진을 위한<br /><span>혁신형 기업</span></div>
            </div>
            <img class="center" src="@/assets/img/content/company/ct_company_1.png" />
            <div class="brunch right">
              <img class="dot" src="@/assets/img/icon/line_dot.png" />
              <img class="vision" src="@/assets/img/icon/ic_fruit.png" />
              <div class="label"><span>비전</span>VISION</div>
              <div class="description">
                <span>제약 생산 환경 및</span><br />
                <span>의약품의 품질향상</span>
                에 기여하는 기업<br />
                <div class="spacer"></div>
                <span>4차 산업혁명</span>
                을 주도하는 기업
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="sub-title"><span>애거슨 네트워크</span> (AGATHON NETWORK)</div>
          <div class="content-container">
            <img src="@/assets/img/content/company/ct_company_2.png" />
            <div class="table">
              <div class="row">
                <div class="column">
                  <img src="@/assets/img/icon/ic_setting.png" />
                  <div class="text">GMP 시스템 환경 구축</div>
                </div>
                <div class="line-vertical top"></div>
                <div class="column">
                  <img src="@/assets/img/icon/ic_pill.png" />
                  <div class="text">신약 연구 및 개발</div>
                </div>
              </div>
              <div class="line-horizontal"></div>
              <div class="row">
                <div class="column">
                  <img src="@/assets/img/icon/ic_factory.png" />
                  <div class="text">공장 자동화 시스템 구축</div>
                </div>
                <div class="line-vertical bottom"></div>
                <div class="column">
                  <img src="@/assets/img/icon/ic_drug.png" />
                  <div class="text">임상/비임상 원료 생산</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>

<style scoped>

.banner {
  width: 100%;
  min-width: 1192px;
  height: 145px;
  margin: 0 auto;
  background-image: url('~@/assets/img/banner/banner_company.png');
}

.title-wrapper {
  position: relative;
  width: 1192px;
  height: 100%;
  margin: 0 auto;
  font-family: NanumBarunGothic;
  font-size: 28px;
  font-weight: 700;
  color: #ffffff;
}

.title {
  position: absolute;
  left: 0;
  bottom: 20px;
}

.container-wrapper {
  width: 100%;
}

.container {
  width: 1192px;
  margin: 0 auto;
}

.section {
  padding-top: 46px;
  margin-bottom: 267px;
}

.section:last-child {
  margin-bottom: 114px;
}

.section .sub-title {
  margin-bottom: 100px;
  font-family: NanumBarunGothic;
  font-size: 24px;
  color: #383838;
}

.section .sub-title span {
  font-weight: 700;
}

.content-container {  
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.table {
  width: 614px;
  height: 335px;
  border-radius: 9px;
  border: solid 1px #bcbcbc;
}

.table .row {
  display: flex;
  position: relative;
  height: 50%;
}

.table .row .column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.text {
  margin-top: 16px;
  font-family: NanumBarunGothic;
  font-size: 17px;
  color: #383838;
}

.line-vertical {
  position: absolute;
  left: 50%;
  width: 1px;
  height: 145px;
  opacity: 0.38;
  background-color: #afafaf;
}

.line-vertical.top {
  bottom: 0;
}

.line-vertical.bottom {
  top: 0;
}

.line-horizontal {
  width: 569px;
  height: 1px;
  margin: 0 auto;
  opacity: 0.38;
  background-color: #afafaf;
}

.content-wrapper {
  display: flex;
  justify-content: center;
  height: 370px;
}

.content-wrapper .brunch {
  position: relative;
  width: 412px;
  height: 100%;
}

.content-wrapper .brunch.left .dot {
  position: absolute;
  top: 139px;
  right: -25px;
}

.content-wrapper .brunch.left .mission {
  position: absolute;
  top: 60px;
  right: 75px;
}

.content-wrapper .brunch.left .label {
  position: absolute;
  top: 118px;
  right: 75px;
  font-family: NanumBarunGothic;
  font-size: 19px;
  font-weight: 300; 
  color: #313e77;
}

.content-wrapper .brunch .label span {
  margin-right: 5px;
  font-family: NanumBarunGothic;
  font-size: 28px;
  font-weight: 700; 
  color: #313e77;
}

.content-wrapper .brunch.left .description {
  position: absolute;
  top: 170px;
  right: 75px;
  font-family: NanumBarunGothic;
  font-size: 21px;
  color: #383838;
  text-align: right;
  letter-spacing: -1px;
}

.content-wrapper .brunch .description span {
  font-weight: 700;
}

.content-wrapper .brunch.right .dot {
  position: absolute;
  top: 139px;
  left: -25px;
  z-index: -1;
}

.content-wrapper .brunch.right .vision {
  position: absolute;
  top: 60px;
  left: 75px;
}

.content-wrapper .brunch.right .label {
  position: absolute;
  top: 118px;
  left: 75px;
  font-family: NanumBarunGothic;
  font-size: 19px;
  font-weight: 300; 
  color: #313e77;
}

.content-wrapper .brunch .label span {
  margin-right: 5px;
  font-family: NanumBarunGothic;
  font-size: 28px;
  font-weight: 700; 
  color: #313e77;
}

.content-wrapper .brunch.right .description {
  position: absolute;
  top: 170px;
  left: 75px;
  font-family: NanumBarunGothic;
  font-size: 21px;
  color: #383838;
  text-align: left;
  letter-spacing: -1px;
}

.content-wrapper .brunch.right .description .spacer {
  height: 7px;
}

.content-wrapper .brunch .description span {
  font-weight: 700;
}

</style>