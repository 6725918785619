<template>
  <div id="app">    
    <router-view/>
  </div>
</template>

<script>
export default {
  
}
</script>

<style lang="stylus">

body {
  margin: 0;
}

ul {
  margin: 0;
}

</style>
