<template>
  <div class="wrapper">
    <div class="container">
      <img src="@/assets/img/logo/logo_white.png" />
      <div class="information">
        <div class="address">경기도 화성시 동탄면 동탄산단 9길, 12 (경기도 화성시 동탄면 방교리 826-10) </div>
        <div class="contact">
          <div>TEL 031 831 5778</div>
          <div>FAX  031 831 5779</div>
          <div>E-mail  agathon@agathon.co.kr</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>

<style scoped>

.wrapper {
  background-color: #191919;
  overflow: auto;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 680px;
  height: 69px;
  margin: 0 auto;
}

.address {
  font-family: NanumBarunGothic;
  font-weight: 300;
  font-size: 13px;
  color: #c9c9c9;
}

.contact {
  margin-top: 3px;
  font-family: NanumBarunGothic;
  font-weight: 300;
  font-size: 13px;
  color: #c9c9c9;
}

.contact > div {
  float: left;
}

.contact > div:nth-child(n+2) {
  margin-left: 34px;
}

</style>