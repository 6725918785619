<template>
  <div>
    <div class="banner">
      <div class="title-wrapper">
        <div class="title">GMP Consulting</div>
      </div>
    </div>
    <div class="container-wrapper">
      <div class="container">
        <div class="section">
          <div class="sub-title"><span>정보화전략계획 </span>(Information Strategy Planning)</div>
          <div class="content-wrapper">
            <div class="explanation">정보화계획 수립 단계부터 GMP 인증과 각종 규제 대응을 반영한 제약산업에 특화된 ISP 수립 서비스를 제공합니다.</div>
            <div class="explanation">다년 간의 CSV 수행을 통해 확보된 제약산업 정보화 DB, 정보화 수준 자료를 활용하여 TOP tier, GMP 규제, Best practice와 GMP 분석 및<br />정보화 수준평가, 시스템 별 URS 작성 등의 특화된 서비스를 제공해 드립니다.</div>
            <div class="image-wrapper">
              <img src="@/assets/img/content/consulting/ct_ips.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'main'
}
</script>

<style scoped>

.banner {
  width: 100%;
  min-width: 1192px;
  height: 145px;
  margin: 0 auto;
  background-image: url('~@/assets/img/banner/banner_company.png');
}

.title-wrapper {
  position: relative;
  width: 1192px;
  height: 100%;
  margin: 0 auto;
  font-family: NanumBarunGothic;
  font-size: 28px;
  font-weight: 700;
  color: #ffffff;
}

.title-wrapper .title {
  position: absolute;
  left: 0;
  bottom: 20px;
}

.container-wrapper {
  width: 100%;
}

.container {
  width: 1192px;
  margin: 0 auto;
}

.section {
  padding-top: 46px;
  margin-bottom: 114px;
}

.section .sub-title {
  font-family: NanumBarunGothic;
  font-size: 24px;
  color: #383838;
}

.section .sub-title span {
  font-weight: 700;
}

.content-wrapper {
  margin-top: 18px;
}

.content-wrapper .explanation {
  font-family: NanumBarunGothic;
  font-size: 17px;
  color: #383838;
}

.content-wrapper .explanation:nth-child(n+2) {
  margin-top: 12px;
}

.content-wrapper .image-wrapper {
  display: flex;
  width: 100%;
  margin-top: 28px;
}

.content-wrapper .image-wrapper img {
  width: 1100px;
  margin: 0 auto;
}

</style>