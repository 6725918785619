<template>
  <div>
    <div class="banner">
      <div class="title-wrapper">
        <div class="title">GMP Consulting</div>
      </div>
    </div>
    <div class="container-wrapper">
      <div class="container">
        <div class="section">
          <div class="sub-title">정기검토 <span>(Periodic Review)</span></div>
          <div class="content-wrapper">
            <div class="box">
              <div class="subject">AGATHON의<br />GMP 시스템 정기 검토 서비스 제공을 통해,<br />다음의 효과를 약속 드립니다.</div>
              <ul>
                <li>
                  <div class="number">①</div>
                  <div class="text">현 제약사 품질보증 및 관리 기준의 정당성을 증명.</div>
                </li>
                <li>
                  <div class="number">②</div>
                  <div class="text">지속적으로 규제 항목에 부합하도록 시스템을 운영하고 있다는 것을 보증.</div>
                </li>
                <li>
                  <div class="number">③</div>
                  <div class="text">최신 규정 및 요구 기준에 따른 GMP 시스템의 개선/개발.</div>
                </li>                
              </ul>
            </div>
            <img src="@/assets/img/content/consulting/ct_review_1.png" />
          </div>
        </div>
        <div class="section">
          <div class="explanation"><span>AGATHON</span>의 체계적인 위험 기반 접근법 및 시스템에 대한 전문 지식을 바탕으로 효율적이며, 분석 서비스 제공을 약속 드립니다.</div>
          <div class="card-container">
            <div class="card">
              <div class="card-title">RA 수행 예시</div>
              <div class="card-text">
                <img src="@/assets/img/content/consulting/ct_review_2.png" />
              </div>
            </div>
            <div class="card">
              <div class="card-image">
                <img src="@/assets/img/content/consulting/ct_review_3.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'main'
}
</script>

<style scoped>

.banner {
  width: 100%;
  min-width: 1192px;
  height: 145px;
  margin: 0 auto;
  background-image: url('~@/assets/img/banner/banner_company.png');
}

.title-wrapper {
  position: relative;
  width: 1192px;
  height: 100%;
  margin: 0 auto;
  font-family: NanumBarunGothic;
  font-size: 28px;
  font-weight: 700;
  color: #ffffff;
}

.title-wrapper .title {
  position: absolute;
  left: 0;
  bottom: 20px;
}

.container-wrapper {
  width: 100%;
}

.container-wrapper .box {
  width: 500px;
  height: 580px;
  background-image: url('~@/assets/img/bg/bg_consulting_review.png');
}

.container {
  width: 1192px;
  margin: 0 auto;
}

.section {
  padding-top: 46px;
  margin-bottom: 114px;
}

.section .sub-title {
  font-family: NanumBarunGothic;
  font-size: 24px;
  font-weight: 700;
  color: #383838;
}

.section .sub-title span {
  font-weight: normal;
}

.section .explanation {
  font-family: NanumBarunGothic;
  font-size: 18px;
  font-weight: 300;
  color: #383838;
}

.section .explanation span {
  font-size: 22px;
  font-weight: 700;
  color: #313e77;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 33px;
}

.container-wrapper .box {
  width: 524px;
  height: 598px;
  padding: 18px;
  background-image: url('~@/assets/img/bg/bg_consulting_review.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.container-wrapper .box .subject {
  font-family: NanumBarunGothic;
  font-size: 22px;
  font-weight: 700;
  color: #ffffff;
}

.container-wrapper .box ul {
  margin-top: 35px;
  padding-left: 0;
  list-style: none;
}

.container-wrapper .box ul li {
  display: flex;
  margin-bottom: 20px;
  font-family: NanumBarunGothic;
  font-size: 16px; 
  color: #ffffff;
}

.container-wrapper .box ul li .number {

}

.container-wrapper .box ul li .text {
  margin-left: 5px;
  letter-spacing: -2px;
}

.section .card-container {
  display: flex;
  justify-content: center;
  margin-top: 28px;
}

.section .card-container .card {
  border: solid 1px #bcbcbc;
}

.section .card-container .card:nth-child(n+2) {
  margin-left: 27px;
}

.section .card-container .card .card-title {
  width: 490px;
  height: 40px;
  line-height: 40px;
  background-color: #313e77;
  font-family: NanumBarunGothic;
  font-size: 17px;
  color: #ffffff;
  text-align: center;
}

.section .card-container .card .card-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 494px;
}

.section .card-container .card .card-image {
  width: 598px;
  height: 534px;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>