<template>
  <div v-if="isAuthenticated">
    <div class="banner">
      <div class="title-wrapper">
        <div class="title">Contact</div>
      </div>
    </div>
    <div class="container-wrapper">
      <div class="container">
        <div class="section">
          <div class="sub-title"><span>공지사항</span>(관리자 모드)</div>
          <div class="content-wrapper">
            <div class="content">
              <table>
                <thead>
                  <th width="8%">번호</th>
                  <th width="60%">제목</th>
                  <th width="10%">작성자</th>
                  <th width="12%">작성일</th>
                  <th width="12%">삭제</th>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, idx) in items"
                    :key="`n-${idx}`"
                  >
                    <td align="center">{{ idx+1 }}</td>
                    <td class="pointer" @click="modify(item.id)">{{ item.title }}</td>
                    <td align="center">관리자</td>
                    <td align="center">{{ getDate(item.regdate) }}</td>
                    <td align="center">
                      <button class="btn" @click="removeItem(item.id)">삭제</button>
                    </td>
                  </tr>
                </tbody>                
              </table>
            </div>
            <div class="btn-wrapper">
              <div
                class="btn"
                @click="addNotice()"
              >
                글쓰기
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal :showModal.sync="showModal" :writer="writer" :item="selectedItem" @close="handleClose()"></modal>
  </div>
</template>

<script>
import Modal from '@/components/modal/notice'

export default {
  layout: 'main',
  data() {
    return {      
      isAuthenticated: false,

      items: [],
      selectedItem: null,

      writer: null,

      showModal: false
    }
  },
  components: {
    'modal': Modal
  },
  created() {
    
  },
  mounted() {
    this.checkAuth()
    //this.showPrompt()
  },
  methods: {
    showPrompt() {
      let id = prompt('아이디: ')
      let pwd = prompt('비밀번호: ')

      if (id && pwd) {
        this.singin(id, pwd)
      } else {
        alert('아이디 또는 비밀번호가 틀렸습니다.')
        this.$router.replace({ path: '/notice' })
      }
    },
    singin(id, pwd) {
      this.$axios.post('/api/auth/login', {
        id: id,
        pwd: pwd
      })
      .then(res => {
        if (res.status === 201) {
          this.isAuthenticated = true
          this.writer = res.data.userid
        }
      })
      .catch(err => {
        console.error(err)
        alert('인증 실패')
        this.$router.replace({ path: '/notice' })
      })
    },
    checkAuth() {
      this.$axios.post('/api/auth/check')
      .then(res => {
        this.isAuthenticated = res.status === 200
        if (!this.isAuthenticated) {
          this.showPrompt()
        } else {
          this.writer = res.data.userid
        }
      })
      .catch(err => {
        console.error(err)
        this.showPrompt()
      })
    },
    getItems() {
      this.$axios.get('/api/notice?_=' + new Date().getTime())
      .then(res => {
        this.items = res.data.data.result
      })
      .catch(err => {
        console.log(err)
      })
    },
    getDate(dateStr) {
      const regDate = new Date(dateStr)

      let year = regDate.getFullYear()
      let month = regDate.getMonth() + 1
      month = month >= 10 ? month : '0' + month
      let date = regDate.getDate() + 1
      date = date >= 10 ? date : '0' + date
      
      return year + '-' + month + '-' + date
    },
    addNotice() {
      this.showModal = true
    },
    removeItem(id) {
      if (confirm('삭제하시겠습니까?')) {
        this.$axios.delete('/api/notice/' + id)
        .then(res => {
          console.log(res.status)
          alert('삭제되었습니다.')
          this.getItems()
        })
        .catch(err => {
          console.error(err)
        })
      }
    },
    modify(id) {
      this.$axios.get('/api/notice/' + id + '/admin')
      .then(res => {
        if (res.data.resultCode > 0) {
          this.selectedItem = res.data.data.result
          this.showModal = true
        } else {
          alert('해당 공지사항이 존재하지 않습니다.')
        }
      })
      .catch(err => {
        console.error(err)
      })
    },
    handleClose() {
      this.selectedItem = null
      this.getItems()
    }
  },
  watch: {
    isAuthenticated: function(val) {
      if (val) {
        this.getItems()
      }
    }
  }
}
</script>

<style scoped>

.banner {
  width: 100%;
  min-width: 1192px;
  height: 145px;
  margin: 0 auto;
  background-image: url('~@/assets/img/banner/banner_company.png');
}

.title-wrapper {
  position: relative;
  width: 1192px;
  height: 100%;
  margin: 0 auto;
  font-family: NanumBarunGothic;
  font-size: 28px;
  font-weight: 700;
  color: #ffffff;
}

.title-wrapper .title {
  position: absolute;
  left: 0;
  bottom: 20px;
}

.container-wrapper {
  width: 100%;
}

.container {
  width: 1192px;
  margin: 0 auto;
}

.section {
  padding-top: 46px;
  margin-bottom: 114px;
}

.section .sub-title {
  font-family: NanumBarunGothic;
  font-size: 24px;
  color: #383838;
}

.section .sub-title span {
  font-weight: 700;
}

.content-wrapper {
  margin-top: 40px;
}

.content-wrapper .content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

table {
  width: 100%;
  min-height: 20px;
  border-collapse: collapse;
  border: 0;
  border-top: 1px solid#40538A;
  border-bottom: 1px solid#40538A;
}

thead {
  border-bottom: 1px solid#40538A;
}

th {
  height: 35px;
  color: #40538A;
}

tr {
  height: 40px;
  border-bottom: 1px solid #D4D4D4;
}

td {
  font-family: NanumBarunGothic;
}

.pointer {
  cursor: pointer;
}

.section .btn-wrapper {
  width: 100%;
  margin-top: 25px;
  overflow: auto;
}

.section .btn-wrapper .btn {
  float: right;
  width: 80px;
  height: 35px;
  line-height: 35px;
  background-color: #40538A;
  text-align: center;
  cursor: pointer;
  font-family: NanumBarunGothic;
  color: #fff;
}

button.btn {
  width: 50px;
  height: 30px;
  line-height: 30px;
  border: none;
  background-color: #f44336;
  text-align: center;
  cursor: pointer;
  font-family: NanumBarunGothic;
  color: #fff;
}

</style>