import { render, staticRenderFns } from "./Pqs.vue?vue&type=template&id=2d573824&scoped=true&"
import script from "./Pqs.vue?vue&type=script&lang=js&"
export * from "./Pqs.vue?vue&type=script&lang=js&"
import style0 from "./Pqs.vue?vue&type=style&index=0&id=2d573824&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d573824",
  null
  
)

export default component.exports