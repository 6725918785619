import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Main from '../views/Main.vue'
// Company
import Index from '../components/company/Index.vue'
import History from '../components/company/History.vue'
import Organization from '../components/company/Organization.vue'
// Consulting
import Design from '../components/consulting/Design.vue'
import Inspection from '../components/consulting/Inspection.vue'
import Isp from '../components/consulting/Isp.vue'
import Pqs from '../components/consulting/Pqs.vue'
import Review from '../components/consulting/Review.vue'
import Validation from '../components/consulting/Validation.vue'
// Validation
import Csv from '../components/validation/Csv.vue'
import Evaluation from '../components/validation/Evaluation.vue'
// Automation
import Field from '../components/automation/Field.vue'
import Industry from '../components/automation/Industry.vue'
import Solution from '../components/automation/Solution.vue'
import Type from '../components/automation/Type.vue'
// Contact
import Location from '../components/contact/Location.vue'
import Manager from '../components/contact/Manager.vue'
// Notice
import Notice from '../components/notice/Notice.vue'
import Admin from '../components/notice/Admin.vue'
import NoticeList from '../components/notice/NoticeList.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/company',
    name: 'Company',
    component: Main,
    children: [
      {
        path: '/',
        name: 'Index',
        component: Index
      },
      {
        path: 'history',
        name: 'History',
        component: History
      },
      {
        path: 'organization',
        name: 'Organization',
        component: Organization
      }
    ]
  },
  {
    path: '/consulting',
    name: 'Consulting',
    component: Main,
    children: [
      {
        path: 'design',
        name: 'Design',
        component: Design
      },
      {
        path: 'inspection',
        name: 'Inspection',
        component: Inspection
      },
      {
        path: 'isp',
        name: 'Isp',
        component: Isp
      },
      {
        path: 'pqs',
        name: 'Pqs',
        component: Pqs
      },
      {
        path: 'review',
        name: 'Review',
        component: Review
      },
      {
        path: 'validation',
        name: 'Validation',
        component: Validation
      }
    ]
  },
  {
    path: '/validation',
    name: 'Validation',
    component: Main,
    children: [
      {
        path: 'csv',
        name: 'Csv',
        component: Csv
      },
      {
        path: 'evaluation',
        name: 'Evaluation',
        component: Evaluation
      }
    ]
  },
  {
    path: '/automation',
    name: 'Automation',
    component: Main,
    children: [
      {
        path: 'field',
        name: 'Field',
        component: Field
      },
      {
        path: 'industry',
        name: 'Industry',
        component: Industry
      },
      {
        path: 'solution',
        name: 'Solution',
        component: Solution
      },
      {
        path: 'type',
        name: 'Type',
        component: Type
      }
    ]
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Main,
    children: [
      {
        path: 'location',
        name: 'Location',
        component: Location
      },
      {
        path: 'manager',
        name: 'Manager',
        component: Manager
      }
    ]
  },
  {
    path: '/notice',
    name: 'Notice',
    component: Main,
    children: [
      {
        path: '/',
        name: 'NoticeList',
        component: NoticeList
      },
      {
        path: 'admin',
        name: 'Admin',
        component: Admin
      },
      {
        path: ':id',
        name: 'Notice',
        component: Notice
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
