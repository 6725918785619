<template>
  <div class="container-wrapper">
    <div class="container">
      <div class="title-wrapper">
        <div class="title"><span>V</span>alidation</div>
      </div>
      <div class="content">
        <div class="box-wrapper">
          <div class="text-wrapper blue">
            <div class="text">시스템/장비 적격성 평가</div>
            <div class="text light">System/Equipment Qualification</div>
          </div>
          <div class="image-wrapper">
            <img src="@/assets/img/content/home/ct_validation_1.png" />
          </div>
          <div class="more">
            <a href="/validation/evaluation">Learn More ▶</a>
          </div>
        </div>
        <div class="box-wrapper">
          <div class="text-wrapper dark">
            <div class="text">컴퓨터 시스템 밸리데이션</div>
            <div class="text light">Computer System Validation</div>
          </div>
          <div class="image-wrapper">
            <img src="@/assets/img/content/home/ct_validation_2.png" />
          </div>
          <div class="more">
            <a href="/validation/csv">Learn More ▶</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>

<style scoped>
  
.container-wrapper {
  width: 100%;
  height: 100%;
  padding: 0 30px;
  background-image: url('~@/assets/img/bg/bg_main_dark.png');
  background-repeat : no-repeat;
  background-size : cover;
}

.container {
  width: 1192px;
  height: 100%;
  margin: 0 auto;
}

.title-wrapper {
  padding-top: 100px;
  padding-left: 9px;
}

.title {
  font-family: NanumBarunGothic;
  font-size: 39px;
  color: #ffffff;
}

.title span {
  color: #99a0f8;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 42px;
}

.box-wrapper {
  width: 519px;
}

.text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 69px;
}

.text-wrapper.blue {
  background-color: #29299a;
}

.text-wrapper.dark {
  background-color: rgba(0, 0, 0, 0.54);
}

.text {
  font-family: NanumBarunGothic;
  font-size: 23px;
  font-weight: 700;
  letter-spacing: -1px;
  color: #ffffff;
}

.text.light {
  font-family: NanumBarunGothic;
  font-size: 19px;
  font-weight: 300;
  letter-spacing: 0px;
  color: #ffffff;
}

.image-wrapper {
  margin-top: 17px;
  width: 100%;
  height: 413px;
  object-fit: contain;
}

.more {
  width: 100%;
  margin-top: 11px;  
  text-align: right;
}

.more a {
  font-family: NanumBarunGothic;
  font-size: 19px;
  font-weight: 700;
  color: #ffffff;
  text-decoration: none;
}



</style>