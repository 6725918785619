<template>
  <div>
    <div class="banner">
      <div class="title-wrapper">
        <div class="title">Automation System</div>
      </div>
    </div>
    <div class="container-wrapper">
      <div class="container">
        <div class="section">
          <div class="sub-title">업무 절차 및 사업 영역<span>(WORK FLOW &amp; BUSINESS SCOPE)</span></div>
          <div class="content-wrapper">
            <img class="fluid" src="@/assets/img/content/automation/ct_process.png" />
            <!-- <div class="procedure">
              <div class="title">PROCEDURE</div>
              <div class="box">사업 검토 및<br />개념 / 상세 설계</div>
              <div class="box">시스템 구축</div>
              <div class="box">안정화 및<br />유지보수</div>
            </div>
            <div class="arrow">
              <div class="title"></div>
              <div class="box"></div>
              <div class="box"></div>
              <div class="box"></div>
            </div>
            <div class="task">
              <div class="title">TASK</div>
              <div class="box">
                <div class="item-wrapper">
                  <div class="icon-container">
                    <img src="@/assets/img/icon/automation/ic_task_1_1.png" />
                  </div>
                  <div class="label">사업성 검토</div>
                </div>
                <div class="item-wrapper">
                  <div class="icon-container">
                    <img src="@/assets/img/icon/automation/ic_task_1_2.png" />
                  </div>
                  <div class="label">개념/기본/상세 설계</div>
                </div>
              </div>
              <div class="box">
                <div class="item-wrapper">
                  <div class="icon-container multiple">
                    <img src="@/assets/img/icon/automation/ic_task_2_1_1.png" />
                    <img src="@/assets/img/icon/automation/ic_task_2_1_2.png" />
                  </div>
                  <div class="label">제작 설치</div>
                </div>
                <div class="item-wrapper">
                  <div class="icon-container multiple">
                    <img src="@/assets/img/icon/automation/ic_task_2_1_1.png" />
                    <img src="@/assets/img/icon/automation/ic_task_2_1_2.png" />
                  </div>
                  <div class="label">프로그래밍 &amp; 시운전</div>
                </div>
              </div>
              <div class="box">
                <div class="item-wrapper">
                  <div class="icon-container">
                    <img src="@/assets/img/icon/automation/ic_task_2_1_1.png" />
                  </div>
                  <div class="label">시스템 안정화</div>
                </div>
                <div class="item-wrapper">
                  <div class="icon-container">
                    <img src="@/assets/img/icon/automation/ic_task_2_1_1.png" />
                  </div>
                  <div class="label">교육 및 인수인계</div>
                </div>
                <div class="item-wrapper">
                  <div class="icon-container">
                    <img src="@/assets/img/icon/automation/ic_task_2_1_1.png" />
                  </div>
                  <div class="label">연간유지보수</div>
                </div>
              </div>
            </div>
            <div class="arrow">
              <div class="title"></div>
              <div class="box"></div>
              <div class="box"></div>
              <div class="box"></div>
            </div>
            <div class="output">
              <div class="title">OUTPUT</div>
              <div class="box">
                <div class="row">
                  <div class="column">수요 조사</div>
                  <div class="column">사업성 평가</div>
                </div>
                <div class="row">
                  <div class="column">예산 산정</div>
                  <div class="column">시스템 설계도면</div>
                </div>
              </div>
              <div class="box">
                <div class="row">
                  <div class="column">시스템 구축</div>
                </div>
                <div class="row">
                  <div class="column">시험계획 및 결과 보고</div>
                </div>
              </div>
              <div class="box">
                <div class="row">
                  <div class="column">시스템 운영 메뉴얼</div>
                </div>
                <div class="row">
                  <div class="column">관리자, 운전자 교육</div>
                </div>
                <div class="row">
                  <div class="column">연간 유지보수활동</div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'main'
}
</script>

<style scoped>

.banner {
  width: 100%;
  min-width: 1192px;
  height: 145px;
  margin: 0 auto;
  background-image: url('~@/assets/img/banner/banner_company.png');
}

.title-wrapper {
  position: relative;
  width: 1192px;
  height: 100%;
  margin: 0 auto;
  font-family: NanumBarunGothic;
  font-size: 28px;
  font-weight: 700;
  color: #ffffff;
}

.title-wrapper .title {
  position: absolute;
  left: 0;
  bottom: 20px;
}

.container-wrapper {
  width: 100%;
}

.container {
  width: 1192px;
  margin: 0 auto;
}

.section {
  padding-top: 46px;
  margin-bottom: 114px;
}

.section .sub-title {
  font-family: NanumBarunGothic;
  font-size: 24px;
  font-weight: 700;
  color: #383838;
}

.section .sub-title span {
  font-weight: normal;
}

.content-wrapper {
  display: flex;
  margin-top: 40px;
}

.content-wrapper img.fluid {
  width: 1100px;
  margin: 0 auto;
}

/* .procedure {
  width: 251px;
}

.procedure .title {
  width: 251px;
  height: 43px;
  border-radius: 6px;
  background-color: #8e959e;
  font-family: NanumBarunGothic;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  line-height: 43px;
  color: #ffffff;
}

.procedure .box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 251px;
  height: 162px;
  margin-top: 8px;
  border-radius: 5px;
  border: solid 1px #bcbcbc;
  background-color: #ffffff;
  font-family: NanumBarunGothic;
  font-size: 16px;
  color: #383838;
  text-align: center;
}

.arrow {
  width: 60px;
}

.arrow .title {
  width: 60px;
  height: 43px;
}

.arrow .box {
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 162px;
}

.task {
  width: 519px;
}

.task .title {
  width: 519px;
  height: 43px;
  border-radius: 6px;
  background-color: #202E5B;
  font-family: NanumBarunGothic;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  line-height: 43px;
  color: #ffffff;
}

.task .box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 519px;
  height: 162px;
  margin-top: 8px;
  border-radius: 5px;
  border: solid 1px #bcbcbc;
  background-color: #ffffff;
  font-family: NanumBarunGothic;
  font-size: 16px;
  color: #383838;
  text-align: center;
}

.task .box .item-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.task .box .item-wrapper:nth-child(n+2) {
  margin-left: 40px;
}

.task .box .item-wrapper .icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 61px;
}

.task .box .item-wrapper .icon-container.multiple {
  justify-content: space-between;
}

.task .box .item-wrapper .label {
  font-family: NanumBarunGothic;
  font-size: 16px;
  color: #383838;
}

.output {
  width: 338px;
}

.output .title {
  width: 338px;
  height: 43px;
  border-radius: 6px;
  background-color: #516DC6;
  font-family: NanumBarunGothic;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  line-height: 43px;
  color: #ffffff;
}

.output .box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 338px;
  height: 162px;
  margin-top: 8px;
  border-radius: 5px;
  border: solid 1px #bcbcbc;
  background-color: #ffffff;
  font-family: NanumBarunGothic;
  font-size: 16px;
  color: #383838;
  text-align: center;
}

.output .box .row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 10px 0;
  font-family: NanumBarunGothic;
  font-size: 18px;
  font-weight: 700;  
  text-align: center;
  color: #425193;
}

.output .box .row .column {
  width: 50%;
  text-align: center;
} */

</style>