<template>
  <div class="container-wrapper">
    <div class="container">
      <div class="content">
        <div class="title">WELCOME TO</div>
        <div class="title white">AGATHON</div>
        <div class="text-container">
          <div class="text">믿고 맡길 수 있는 전문기업</div>
          <div class="text">고객 여러분의 신뢰에 보답하기 위해<br />최선을 다하겠습니다</div>
        </div>
        <div class="more">
          <a href="/company">Learn More ▶</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>

<style scoped>
  
.container-wrapper {
  width: 100%;
  height: 100%;
  background-image: url('~@/assets/img/bg/bg_main.png');
  background-repeat : no-repeat;
  background-size : cover;
}

.container {
  width: 1192px;
  height: 100%;
  margin: 0 auto;
  overflow: auto;
}

.content {
  padding-top: 131px;
  padding-left: 9px;
}

.title {
  font-family: Cafe24Ohsquare;
  font-size: 66px;
  color: #9cacff;
}

.title.white {
  color: #fff;
}

.text-container {
  margin-top: 30px;
}

.text {
  font-family: NanumBarunGothic;
  font-size: 21px;
  color: #ffffff;
}

.text:nth-child(n+1) {
  margin-top: 9px;
}

.more {
  margin-top: 91px;
}

.more a {
  font-family: NanumBarunGothic;
  font-size: 19px;
  font-weight: 700;
  color: #ffffff;
  text-decoration: none;
}

</style>