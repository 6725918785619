<template>
  <div class="container-wrapper">
    <div class="container">
      <div class="title-wrapper">
        <div class="title"><span>C</span>ompany</div>
        <div class="subtitle">아시아 최대규모의 GMP 컨설팅, 최고의 자동화 시스템 솔루션</div>
      </div>
      <div class="content">
        <div class="item-list">
          <div class="item">
            <img src="@/assets/img/icon/ic_mission.png" />
            <div class="text-wrapper">
              <div class="subject"><span>미션</span>MISSION</div>
              <div class="description">인류 건강과 복지증진을 위한 혁신형 기업</div>
            </div>
          </div>
          <div class="item">
            <img src="@/assets/img/icon/ic_vision.png" />
            <div class="text-wrapper">
              <div class="subject"><span>비전</span>VISION</div>
              <div class="description">제약 생산 환경 및 의약품의 품질향상에 기여하는 기업<br />4차 산업혁명을 주도하는 기업</div>
            </div>
          </div>
        </div>
        <div class="table-wrapper">
          <div class="table">
            <div class="table-title">애거슨 네트워크</div>
            <div class="table-content">
              <div class="content-wrapper">
                <div class="table-item">
                  <img src="@/assets/img/content/home/ct_network.png" />
                </div>
                <div class="table-item">
                  <div class="row">
                    <div class="column">
                      <img src="@/assets/img/icon/ic_setting_light.png" />
                      <div class="text">GMP 시스템 환경 구축</div>
                    </div>
                    <div class="line-vertical top"></div>
                    <div class="column">
                      <img src="@/assets/img/icon/ic_pill_light.png" />
                      <div class="text">신약 연구 및 개발</div>
                    </div>
                  </div>
                  <div class="line-horizontal"></div>
                  <div class="row">
                    <div class="column">
                      <img src="@/assets/img/icon/ic_factory_light.png" />
                      <div class="text">공장 자동화 시스템 구축</div>
                    </div>
                    <div class="line-vertical bottom"></div>
                    <div class="column">
                      <img src="@/assets/img/icon/ic_drug_light.png" />
                      <div class="text">임상/비임상 원료 생산</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>

<style scoped>
  
.container-wrapper {
  width: 100%;
  height: 100%;
  background-image: url('~@/assets/img/bg/bg_main_dark.png');
  background-repeat : no-repeat;
  background-size : cover;
  padding: 0 30px;
}

.container {
  width: 1192px;
  height: 100%;
  margin: 0 auto;
}

.title-wrapper {
  position: relative;
  padding-top: 100px;
  padding-left: 9px;
}

.title {
  font-family: NanumBarunGothic;
  font-size: 39px;
  color: #ffffff;
}

.title span {
  color: #99a0f8;
}

.subtitle {
  margin-top: 6px;
  font-family: NanumBarunGothic;
  font-size: 18px;
  font-weight: 300;
  color: #ffffff;
}

.content {
  margin-top: 60px;
  width: 100%;
  overflow: auto;
}

.content .item-list {
  display: flex;
  width: 100%;
}

.content .item-list .item {
  display: flex;
  width: 50%;
  padding-left: 10px;
}

.content .item-list .item img {

}

.content .item-list .item .text-wrapper {
  margin-left: 20px;
  padding-top: 25px;
  font-family: NanumBarunGothic;
  color: #fff;
}

.content .item-list .item .text-wrapper .subject {
  font-weight: 300;
  font-size: 18px;
}

.content .item-list .item .text-wrapper .subject span {
  font-weight: 700;
  font-size: 25px;
  margin-right: 10px;
}

.content .item-list .item .text-wrapper .description {
  margin-top: 6px;
  font-size: 19px;
  line-height: 1.5;
}

.content .table-wrapper {
  width: 100%;
  margin-top: 32px;
}

.content .table-wrapper .table {
  width: 100%;
}

.content .table-wrapper .table .table-title {
  width: 100%;
  height: 43px;
  line-height: 45px;
  background-color: #29299a;
  font-family: NanumBarunGothic;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
}

.content .table-wrapper .table .table-content {
  display: flex;
  align-items: center;
  width: 100%;
  height: 293px;
  background-color: rgba(0, 0, 0, 0.46);
}

.content .table-wrapper .table .table-content .content-wrapper {
  display: flex;
  width: 100%;
  height: 233px;
}

.content .table-wrapper .table .table-content .content-wrapper .table-item {
  width: 100%;
  height: 100%;
  text-align: center;
}

.content .table-wrapper .table .table-content .content-wrapper .table-item:first-child {
  border-right: 1px solid rgba(175, 175, 175, 0.38);
}

.content .table-wrapper .table .table-content .content-wrapper .table-item .row {
  display: flex;
  position: relative;
  height: 50%;
  padding: 0 52px;
}

.content .table-wrapper .table .table-content .content-wrapper .table-item .row .column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.content .table-wrapper .table .table-content .content-wrapper .table-item .row .column .text {
  margin-top: 16px;
  font-family: NanumBarunGothic;
  font-size: 17px;
  color: #fff;
}

</style>