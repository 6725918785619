<template>
  <div v-if="showModal" >
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">

            <div class="modal-header">
              <h3>공지사항</h3>
            </div>

            <div v-if="currentItem" class="modal-body">
              <div class="title">
                <div class="label">
                  <h4>제목</h4>
                </div>
                <input type="text" v-model="currentItem.title" />
              </div>
              <div class="content">
                <div class="label">
                  <h4>내용</h4>
                </div>
                <div class="editor-wrapper">
                  <quillEditor 
                    ref="editor"
                    v-model="currentItem.content"
                    :options="editorOption"
                    @blur="onEditorBlur($event)"
                    @focus="onEditorFocus($event)"
                    @ready="onEditorReady($event)"
                  />
                </div>
              </div>
              <div class="content">
                <div class="label">
                  <h4>파일 첨부</h4>
                </div>
                <div class="file-upload">
                  <button class="btn-upload" @click="selectFile()">파일선택</button>
                  <input id="file-upload" ref="upload" type="file" @change="fileSelected" />
                  <div class="file-list">
                    <!-- 새로운 파일 -->
                    <div
                      v-for="(file, idx) in files"
                      :key="`nf-${idx}`"
                      class="file-item"
                    >
                      <div
                        class="file-label"
                        @click="removeFile(idx)"
                      >
                        {{ file.originalname || file.name }}
                      </div>
                    </div>
                    <!-- //새로운 파일 -->
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button class="modal-default-button light" @click="close()">Cancel</button>
              <button class="modal-default-button" @click="submit()">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
 
import { quillEditor } from 'vue-quill-editor'

export default {
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    writer: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      content: null,

      defaultItem: {
        title: null,
        content: null
      },

      currentItem: null,

      files: [],

      editorOption: {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ size: [ 'small', false, 'large', 'huge' ]}],
            [{ 'header': 1 }, { 'header': 2 }], 
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],       
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['clean']
          ]
        }
      }
    }
  },
  methods: {
    submit() {
      if (!this.validate()) return

      if (this.item) {
        const formData = new FormData()

        const newFiles = this.files.filter(file => !file.filepath)

        for (let file of newFiles) {
          formData.append('doc', file)
        }

        formData.append('item', JSON.stringify(this.currentItem))

        this.$axios({
          method: 'put',
          url: '/api/notice',
          data: formData
        })
        .then(res => {
          if (res.status === 200) {
            alert('수정되었습니다.')
            this.close()
          }
        })
        .catch(err => {
          console.error(err)
        })
      } else {
        const formData = new FormData()

        const newFiles = this.files.filter(file => !file.filepath)

        for (let file of newFiles) {
          formData.append('doc', file)
        }

        formData.append('item', JSON.stringify(this.currentItem))

        this.$axios({
          method: 'post',
          url: '/api/notice',
          data: formData
        })
        .then(res => {
          if (res.status === 200) {
            alert('등록되었습니다.')
            this.close()
          }
        })
        .catch(err => {
          console.error(err)
        })
      }
    },
    close() {
      this.$emit('update:showModal', false)
      this.$emit('close')
      // 초기화
      this.files = []
      this.currentItem = null
    },
    validate() {
      if (!this.currentItem.title) {
        alert('제목을 입력해주세요.')
        return false
      }

      if (!this.currentItem.content) {
        alert('내용을 입력해주세요.')
        return false
      }

      this.currentItem.writer = this.writer

      return true
    },
    onEditorBlur(editor) {
      console.log(editor)
    },
    onEditorFocus(editor) {
      console.log(editor)
    },
    onEditorReady(editor) {
      console.log(editor)
    },
    selectFile() {
      this.$refs.upload.click()
    },
    fileSelected() {
      const file = this.$refs.upload.files[0]
      if (file) {
        this.files.push(file)
      }
    },
    removeFile(index) {
      // 저장된 파일 삭제
      if (this.files[index].path) {
        if (confirm('파일을 삭제하시겠습니까?')) {
          // 파일 삭제 호출
          const id = this.currentItem.id
          this.$axios.delete('/api/notice/' + id + '/file/' + this.files[index].filename)
          .then(res => {
            if (res.status === 200) {
              alert('삭제 되었습니다.')
              this.files.splice(index, 1)
            }
          })
          .catch(err => {
            console.error(err)
          })
        }
      } else {
        this.files.splice(index, 1)
      }
    }
  },
  components: {
    quillEditor
  },
  watch: {
    showModal: function(val) {
      if (val) {
        if (this.item) {
          this.currentItem = Object.assign({}, this.item)
          if (this.item.files) {
            this.files = this.item.files
          }
        } else {
          this.currentItem = Object.assign({}, this.defaultItem)
        }
      }
    }
  }
}
</script>

<style scoped>

label {

}

input {
  width: 100%;
  height: 35px;
  padding: 0 5px;
}

input, .editor-wrapper {
  margin-top: 5px;
  font-size: 15px;
}

.content {
  margin-top: 20px;
}

.quill-editor {
  min-height: 200px;
  max-height: 500px;
  overflow-y: auto;
}

button {
  background-color: #40538A;
  border: none;
  color: white;
  padding: 12px 22px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

button:focus {
  outline: none;
}

button.light {
  padding: 11px 21px;
  background-color: white;
  border: 1px solid #40538A;
  color: #40538A;
}

button.btn-upload {
  color: black;
  background-color: white;
  border: 1px solid black;
  padding: 5px 10px;
  font-size: 14px;
}

.file-upload {
  margin-top: 10px;
}

#file-upload {
  display: none;
}

.file-list {
  padding: 5px;
}

.file-item {
  margin-top: 8px;
}

.file-label {
  display: inline-block;
  cursor: pointer;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 1000px;
  overflow: auto;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color:#000;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>